import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import jsonData from "Assets/contents/contents.json";
// logics
import QuestionLogic from "./Question.logic";
// components
import { Alert, Loading } from "Components";

// styles
import { QuestionSt, StyleContainer } from "Styles/styles";

// img
import RightarrowSmall from "Assets/images/icons/rightarrow_small.svg";
import QuestionIcon from "Assets/images/icons/question_icon.svg";
import AnswerIcon from "Assets/images/icons/answer_icon.svg";
import NewsbuilderSymbol from "Assets/images/icons/newsbuilder_symbol.svg";
import Talk from "Assets/images/icons/playground_talk.png";

const Question = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
  });
  const { state, data, func, questionOpen, contentOpen } = QuestionLogic({
    loading,
    setLoading,
    navigate,
    url: {
      type: urlState.type,
      keyword: urlState.keyword,
    },
  });

  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      type: location.pathname.split("/question/")[1],
    });
  }, [location.pathname]);

  return (
    <>
      {loading && <Loading loading={loading} />}
      <StyleContainer>
        <h1>자주 묻는 질문</h1>
        <ul className="question_nav">
          <li
            className={urlState.type === "news" ? "on" : ""}
            onClick={() => navigate("/question/news")}
          >
            뉴스
          </li>
          {/* 구독수정 */}
          {/* 
          <li
            className={urlState.type === "subscribe" ? "on" : ""}
            onClick={() => navigate("/question/subscribe")}
          >
            구독
          </li>
           */}
        </ul>
      </StyleContainer>

      {urlState.type === "news" ? (
        <>
          <QuestionSt open={contentOpen.news1}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("news1", !contentOpen.news1)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>어떤 내용의 뉴스들이 있나요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" className="mt7" />
                <p className="lh190">
                  위그는 사회, 환경, 테크·과학, 정치·외교, 경제, 문화 등 다양한
                  분야의 소식을 글로벌, 코리아, 찬반, 테크 카테고리로 나눠
                  뉴스로 전달합니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.news2}>
            <div className="qustion_box padding_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("news2", !contentOpen.news2)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p> 초등학교 저학년에게 주제나 내용이 어렵지 않을까요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" className="mt7" />
                <p className="lh190">
                  위그는 1학년, 2학년, 3·4학년, 5·6학년으로 세분화해 학년별
                  관심도에 따라 주제를 선정하고 읽기 난이도를 조정하여 초등학교
                  저학년도 관심을 갖고 쉽게 읽을 수 있게 뉴스를 제공하고
                  있습니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.news3}>
            <div className="qustion_box padding_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("news3", !contentOpen.news3)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>아이들이 작성한 뉴스 및 뉴스레터를 프린트할 수 있나요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" />
                <p className="lh190">
                  네, LMS 내에 나의 뉴스, 나의 뉴스레터로 가셔서 프린트를
                  원하시는 뉴스를 클릭 후 다운로드 받으신 후에 해당 파일을
                  출력하시면 됩니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.news4}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("news4", !contentOpen.news4)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>모바일로도 이용이 가능한가요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" className="mt7" />
                <p className="lh190">
                  모바일로 뉴스를 보거나 뉴스 빌더를 이용하시는 것은 가능하지만
                  아이들이 글을 쓸 때는 키보드를 이용해 타이핑 할 수 있도록
                  데스크탑, 노트북 또는 태블릿 PC 이용을 권장드립니다.
                </p>
              </div>
            </div>
          </QuestionSt>
        </>
      ) : (
        <>
          <QuestionSt open={contentOpen.subscribe1}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("subscribe1", !contentOpen.subscribe1)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>구독에 어떤 서비스가 포함되나요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" />
                <p className="lh190">
                  매주 업데이트 되는 최신 뉴스와 관련 뉴스를 볼 수 있으며, 나의
                  뉴스 및 뉴스레터를 작성하고 발간할 수 있습니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.subscribe2}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("subscribe2", !contentOpen.subscribe2)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>구독 신청은 어떻게 하면 되나요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" />
                <p className="lh190">
                  구독 페이지에서 월간 구독, 연간 구독 중 원하시는 플랜을 선택해
                  결제하시면 됩니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.subscribe3}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("subscribe3", !contentOpen.subscribe3)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>결제는 어떻게 하면 되나요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" className="mt7" />
                <p className="lh190">
                  구독 페이지에서 원하는 플랜을 선택해 구독하기 버튼을 누르면
                  결제 페이지로 이동합니다. 해당 페이지에서 카드 결제를
                  통해구독을 신청할 수 있습니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.subscribe4}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("subscribe4", !contentOpen.subscribe4)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>구독 해지는 어떻게 하나요?</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" />
                <p className="lh190">
                  마이페이지 '구독 관리'에서 나의 플랜에 '관리'에 있는
                  '구독해지' 버튼을 누르면 구독이 해지됩니다.
                </p>
              </div>
            </div>
          </QuestionSt>
          <QuestionSt open={contentOpen.subscribe5}>
            <div className="qustion_box">
              <div
                onClick={() =>
                  func.setContentsOnoff("subscribe5", !contentOpen.subscribe5)
                }
              >
                <img src={QuestionIcon} alt="질문아이콘" />
                <p>환불 규정에 대해 알고 싶어요.</p>
                <img src={RightarrowSmall} alt="아래화살표" className="arrow" />
              </div>
              <div className="answer">
                <img src={AnswerIcon} alt="질문아이콘" className="mt7" />
                <p className="lh190">
                  이미 구독이 시작된 경우 환불이 어려우며 구독 취소시 자동갱신이
                  해지됩니다. 자동갱신이 해지되어도 구독 신청하신 남은 기간 동안
                  서비스 이용이 가능합니다. 구독 해지방법은 마이페이지 '구독
                  관리'에서 나의 플랜에 '관리'에 있는 '구독해지' 버튼을 누르면
                  구독이 해지됩니다.
                </p>
              </div>
            </div>
          </QuestionSt>
        </>
      )}
      <StyleContainer>
        <div className="question_footer">
          <div>
            <div>
              <h3>다른 도움이 필요하신가요?</h3>
              <p>
                도움이 필요하거나 궁금한 점을 <br />
                남겨주시면 위그가 알려드릴게요!
              </p>
              <div className="btns">
                <button onClick={() => func.copyLink()}>이메일 질문하기</button>
                <button
                  onClick={() =>
                    window.open(jsonData.contact_url.kakao, "_blank")
                  }
                >
                  카카오톡 상담하기
                </button>
              </div>
            </div>
            <div>
              <img src={NewsbuilderSymbol} alt="뉴스빌더 심볼 아이콘" />
            </div>
            <img src={Talk} alt="말풍선 아이콘" className="talk" />
          </div>
        </div>
        {/*  얼럿 */}
        {state.alert ? (
          <Alert
            title={state.alert.title}
            sub={state.alert.sub}
            isOk={state.alert.isOk}
            isCancel={state.alert.isCancel}
          />
        ) : null}
      </StyleContainer>
    </>
  );
};

export default Question;
