import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// json
import jsonData from "Assets/contents/contents.json";
import News01 from "Assets/images/icons/landing_news01.svg";

// components
import { Alert } from "Components";
// img
import NewsEX from "Assets/images/icons/playground_ex.png";
import Mail from "Assets/images/icons/playground_mail.png";
import Talk from "Assets/images/icons/playground_talk.png";
import NewsbuilderSymbol from "Assets/images/icons/newsbuilder_symbol.svg";
import Share from "Assets/images/icons/playground_share_icon.svg";
import Heart from "Assets/images/icons/playground_heart.svg";
import LikedHeart from "Assets/images/icons/playground_like_heart.svg";
import Close from "Assets/images/icons/close2.svg";

// styles
import { Container } from "Styles/styles";

// logics
import PlayGroundLogic from "./PlayGround.logic";
import moment from "moment";

const PlayGround = ({ loading, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlState, setUrlState] = useState({
    current: "",
    type: "",
    keyword: "",
  });
  const { state, data, func } = PlayGroundLogic({
    loading,
    setLoading,
    navigate,
    url: urlState,
  });
  useEffect(() => {
    setUrlState({
      ...urlState,
      current: location.pathname,
      type: location.pathname.split("/playground/")[1].split("/")[0],
      keyword: location.pathname.split("/playground/")[1].split("/")[1],
    });
  }, [location.pathname]);
  return (
    <Container>
      뉴스 놀이터
      {urlState.type === "main" ? (
        <>
          <div className="playground">
            <div className="playround_title">
              <div>
                <img src={Mail} alt="메일 아이콘" />
                <img src={NewsbuilderSymbol} alt="뉴스빌더심볼 아이콘" />
                <img src={Talk} alt="말풍선 아이콘" />
                <h2>
                  <span>뉴스 놀이터</span> 최신 뉴스
                </h2>
                <p>
                  매주 업데이트 되는 뉴스를 읽으며 문해력과 시사상식을 높이고{" "}
                  <br />
                  댓글을 쓰고 읽으며 생각을 나눠요!
                </p>
              </div>
            </div>


            <div className="tab">
              <span
                className={state.grade_type === 1 ? "on" : ""}
                onClick={() => func.setStateValue("grade_type", 1)}
              >
                1학년
              </span>
              <span
                className={state.grade_type === 2 ? "on" : ""}
                onClick={() => func.setStateValue("grade_type", 2)}
              >
                2학년
              </span>
              <span
                className={state.grade_type === "3" ? "on" : ""}
                onClick={() => func.setStateValue("grade_type", "3")}
              >
                3 · 4학년
              </span>
              <span
                className={state.grade_type === "5" ? "on" : ""}
                onClick={() => func.setStateValue("grade_type", "5")}
              >
                5 · 6학년
              </span>
            </div>
            


            <div className="news">
              <h2 className="news1_text">
                <span>이번 주 </span>
                뉴스
              </h2>
              <ul>
                {state.data_week1 ? (
                  state.data_week1.week1 &&
                  state.data_week1.week1.length > 0 ? (
                    state.data_week1.week1.map((item) => (
                      <li key={`news_${item.id}`}>
                        {item.images && item.images.image_url ? (
                          <img
                            onClick={() => navigate(`/news/${item.id}`)}
                            src={item.images.image_url}
                            alt="이번주 뉴스 이미지"
                          />
                        ) : (
                          <div
                            onClick={() => navigate(`/news/${item.id}`)}
                            className="no_image"
                          ></div>
                        )}
                        <div
                          className="tagbox"
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          {item.first_categories ? (
                            <span>{item.first_categories.category_name}</span>
                          ) : null}
                          {item.second_categories ? (
                            <span>{item.second_categories.category_name}</span>
                          ) : null}
                        </div>
                        <h4 onClick={() => navigate(`/news/${item.id}`)}>
                          {item.title}
                        </h4>
                        <h6>
                          <div className="username-box">
                          {item.published_at
                            ? moment(item.published_at).format("YYYY.MM.DD.")
                            : null}

                          <div className="iconbox">
                            <span onClick={() => func.likedNews(item.id)}>
                              <img
                                src={item.is_liked ? LikedHeart : Heart}
                                alt="하트 아이콘"
                              />
                            </span>
                          </div>


                          </div>
                        </h6>
                        
                      </li>
                    ))
                  ) : (
                    <li className="news_none">뉴스가 없습니다.</li>
                  )
                ) : (
                  <li className="news_none">뉴스가 없습니다.</li>
                )}
              </ul>
            </div>




            <div className="news">
              <h2>
                <span>
                  {state.data_week2
                    ? `${state.data_week2.month}월 ${state.data_week2.week}주차`
                    : null}{" "}
                </span>
                뉴스
              </h2>
              <ul>
                {state.data_week2 ? (
                  state.data_week2.week2 &&
                  state.data_week2.week2.length > 0 ? (
                    state.data_week2.week2.map((item) => (
                      <li key={`news_${item.id}`}>
                        {item.images && item.images.image_url ? (
                          <img
                            onClick={() => navigate(`/news/${item.id}`)}
                            src={item.images.image_url}
                            alt="이번주 뉴스 이미지"
                          />
                        ) : (
                          <div
                            onClick={() => navigate(`/news/${item.id}`)}
                            className="no_image"
                          ></div>
                        )}
                        <div
                          className="tagbox"
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          {item.first_categories ? (
                            <span>{item.first_categories.category_name}</span>
                          ) : null}
                          {item.second_categories ? (
                            <span>{item.second_categories.category_name}</span>
                          ) : null}
                        </div>
                        <h4 onClick={() => navigate(`/news/${item.id}`)}>
                          {item.title}
                        </h4>
                        <h6>
                          <div className="username-box">
                          {item.published_at
                            ? moment(item.published_at).format("YYYY.MM.DD.")
                            : null}
                            <div className="iconbox">
                              <span onClick={() => func.likedNews(item.id)}>
                                <img
                                  src={item.is_liked ? LikedHeart : Heart}
                                  alt="하트 아이콘"
                                />
                              </span>
                            </div>
                          </div>
                        </h6>

                        
                      </li>
                    ))
                  ) : (
                    <li className="news_none">뉴스가 없습니다.</li>
                  )
                ) : (
                  <li className="news_none">뉴스가 없습니다.</li>
                )}
              </ul>
            </div>
            <div className="news">
              <h2>
                <span>
                  {state.data_week3
                    ? `${state.data_week3.month}월 ${state.data_week3.week}주차`
                    : null}{" "}
                </span>
                뉴스
              </h2>
              <ul>
                {state.data_week3 ? (
                  state.data_week3.week3 &&
                  state.data_week3.week3.length > 0 ? (
                    state.data_week3.week3.map((item) => (
                      <li key={`news_${item.id}`}>
                        {item.images && item.images.image_url ? (
                          <img
                            onClick={() => navigate(`/news/${item.id}`)}
                            src={item.images.image_url}
                            alt="이번주 뉴스 이미지"
                          />
                        ) : (
                          <div
                            onClick={() => navigate(`/news/${item.id}`)}
                            className="no_image"
                          ></div>
                        )}
                        <div
                          className="tagbox"
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          {item.first_categories ? (
                            <span>{item.first_categories.category_name}</span>
                          ) : null}
                          {item.second_categories ? (
                            <span>{item.second_categories.category_name}</span>
                          ) : null}
                        </div>
                        <h4 onClick={() => navigate(`/news/${item.id}`)}>
                          {item.title}
                        </h4>
                        <h6>
                        <div className="username-box">
                          {item.published_at
                            ? moment(item.published_at).format("YYYY.MM.DD.")
                            : null}
                            <div className="iconbox">
                              <span onClick={() => func.likedNews(item.id)}>
                                <img
                                  src={item.is_liked ? LikedHeart : Heart}
                                  alt="하트 아이콘"
                                />
                              </span>
                            </div>
                          </div>
                        </h6>
                        
                      </li>
                    ))
                  ) : (
                    <li className="news_none">뉴스가 없습니다.</li>
                  )
                ) : (
                  <li className="news_none">뉴스가 없습니다.</li>
                )}
              </ul>
            </div>



          {/*
            <div className="news">
              <h2>
                <span>
                  {state.data_week4
                    ? `${state.data_week4.month}월 ${state.data_week4.week}주차`
                    : null}{" "}
                </span>
                뉴스
              </h2>
              <ul>
                {state.data_week4 ? (
                  state.data_week4.week4 &&
                  state.data_week4.week4.length > 0 ? (
                    state.data_week4.week4.map((item) => (
                      <li key={`news_${item.id}`}>
                        {item.images && item.images.image_url ? (
                          <img
                            onClick={() => navigate(`/news/${item.id}`)}
                            src={item.images.image_url}
                            alt="이번주 뉴스 이미지"
                          />
                        ) : (
                          <div
                            onClick={() => navigate(`/news/${item.id}`)}
                            className="no_image"
                          ></div>
                        )}
                        <div
                          className="tagbox"
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          {item.first_categories ? (
                            <span>{item.first_categories.category_name}</span>
                          ) : null}
                          {item.second_categories ? (
                            <span>{item.second_categories.category_name}</span>
                          ) : null}
                        </div>
                        <h4 onClick={() => navigate(`/news/${item.id}`)}>
                          {item.title}
                        </h4>
                        <h6>
                        <div className="username-box">
                          {item.published_at
                            ? moment(item.published_at).format("YYYY.MM.DD.")
                            : null}
                              <div className="iconbox">
                                <span onClick={() => func.likedNews(item.id)}>
                                  <img
                                    src={item.is_liked ? LikedHeart : Heart}
                                    alt="하트 아이콘"
                                  />
                                </span>
                              </div>
                            </div>
                        </h6>
                      </li>
                    ))
                  ) : (
                    <li className="news_none">뉴스가 없습니다.</li>
                  )
                ) : (
                  <li className="news_none">뉴스가 없습니다.</li>
                )}
              </ul>
            </div>
          */}

          </div>
        </>
      ) : (

        // 뉴스놀이터 검색 결과 화면
        <div className="playground search">
          <h2 className="mt80">검색 결과</h2>
          <div className="search_open">
            <div>
              <input
                type="text"
                placeholder="검색하세요."
                id="search"
                defaultValue={
                  urlState.keyword.includes("%")
                    ? decodeURI(urlState.keyword)
                    : urlState.keyword
                }

                // onChange={({ target: { value } }) =>
                  // func.setStateValue("search_keyword", value)
                // }

                // onKeyDown={(e) => activeEnter(e)}
                // onKeyPress={(e) => {
 
                  /*  
                  onKeyDown={(e) => {
                    if (e.key !== "Enter") return;
                    if (e.shiftKey) return;
                    e.preventDefault();
                    func.searchList();
                  }}
                    */

                  onKeyDown={(e) => {
                    if(e.key === "Enter"){
                      e.preventDefault();

                      console.log("e : ", e.target.value);
                      // func.setStateValue("search_keyword", e.target.value);

                      func.searchList(e.target.value);
                    }
                  }}



              />
              <img
                src={Close}
                alt="닫기 아이콘"
                onClick={() => func.setStateValue("search_keyword", "")}
              />
            </div>
          </div>
          <h2>
            <span> {state.data_list ? state.data_list.total : 0} </span>개의 뉴스
          </h2>
          <div className="news playground">
            <ul className="search_done">
              {state.data_list ? (
                state.data_list.news && state.data_list.news.length > 0 ? (
                  state.data_list.news.map((item) => (
                    <li key={`news_${item.id}`}>
                      {item.images && item.images.image_url ? (
                        <img
                          onClick={() => navigate(`/news/${item.id}`)}
                          src={item.images.image_url}
                          alt="이번주 뉴스 이미지"
                        />
                      ) : (
                        <div
                          onClick={() => navigate(`/news/${item.id}`)}
                          className="no_image"
                        ></div>
                      )}
                      <div>
                        <div
                          className="tagbox"
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          {item.contents ? (                            
                              item.contents.grade == 1
                                ? <span>1학년</span>
                                : item.contents.grade == 2
                                ? <span>2학년</span>
                                : item.contents.grade == 3
                                ? <span>3,4학년</span>
                                : item.contents.grade == 4
                                ? <span>5,6학년</span>
                                : ""
                          ) : null}
                          {item.first_categories ? (
                            <span>{item.first_categories.category_name}</span>
                          ) : null}
                          {item.second_categories ? (
                            <span>{item.second_categories.category_name}</span>
                          ) : null}
                        </div>
                        <h4 onClick={() => navigate(`/news/${item.id}`)}>
                          {item.title.includes(
                            urlState.keyword.includes("%")
                              ? decodeURI(urlState.keyword)
                              : urlState.keyword
                          ) ? (
                            <>
                              {
                                item.title.split(
                                  urlState.keyword.includes("%")
                                    ? decodeURI(urlState.keyword)
                                    : urlState.keyword
                                )[0]
                              }
                              {/* 검색 키워드 강조 단어 */}
                              <strong>
                                {urlState.keyword.includes("%")
                                  ? decodeURI(urlState.keyword)
                                  : urlState.keyword}
                              </strong>
                              {
                                item.title.split(
                                  urlState.keyword.includes("%")
                                    ? decodeURI(urlState.keyword)
                                    : urlState.keyword
                                )[1]
                              }
                            </>
                          ) : (
                            item.title
                          )}
                        </h4>
                        {/* 검색 뉴스 내용 */}
                        <p>
                          {item.contents ? (
                              item.contents.content.includes(
                                  urlState.keyword.includes("%")
                                  ? decodeURI(urlState.keyword)
                                  : urlState.keyword
                              ) ? (
                                  <>
                                  {
                                      item.contents.content.split(
                                      urlState.keyword.includes("%")
                                          ? decodeURI(urlState.keyword)
                                          : urlState.keyword
                                      )[0]
                                  }
                                  <strong>
                                      {urlState.keyword.includes("%")
                                      ? decodeURI(urlState.keyword)
                                      : urlState.keyword}
                                  </strong>
                                  {
                                      item.contents.content.split(
                                      urlState.keyword.includes("%")
                                          ? decodeURI(urlState.keyword)
                                          : urlState.keyword
                                      )[1]
                                  }
                                  </>
                              ) : (
                                  item.contents.content
                              )
                          ) : (
                              ""
                          )}   
                          </p>


                        <h6>
                          {item.published_at
                            ? moment(item.published_at).format("YYYY.MM.DD.")
                            : null}
                        </h6>
                      </div>

                      {/* TODO : 아이콘은 css 만 적용해두고 숨겨두기 */}
                      {/* <div className="iconbox">
                        <span onClick={() => func.likedNews(item.id)}>
                          <img
                            src={item.is_liked ? LikedHeart : Heart}
                            alt="하트 아이콘"
                          />
                        </span>
                      </div> */}
                    </li>
                  ))

                ) : (
                  <li className="news_none">뉴스가 없습니다.</li>
                )
              ) : (
                <li className="news_none">뉴스가 없습니다.</li>
              )}
            </ul>
          </div>


          <h2>
            <span> {state.data_list2 ? state.data_list2.total : 0} </span>개의 우리들 뉴스
          </h2>

          <div className="news our-news">
            <ul className="search_done">
              {state.data_list2 ? (

                state.data_list2.news && state.data_list2.news.length > 0 ? (
                  state.data_list2.news.map((item) => (
                    <li key={`news_${item.id}`}>
                      {item.images && item.images.image_url ? (
                        <img
                          onClick={() => navigate(`/news/${item.id}`)}
                          src={item.images.image_url}
                          alt="이번주 뉴스 이미지"
                        />
                      ) : (
                        <div
                          onClick={() => navigate(`/news/${item.id}`)}
                          className="no_image"
                        ></div>
                      )}

                      <div>
                        <div
                          className="tagbox"
                          onClick={() => navigate(`/news/${item.id}`)}
                        >
                          {item.first_categories ? (
                            <span>{item.first_categories.category_name}</span>
                          ) : null}
                          {item.second_categories ? (
                            <span>{item.second_categories.category_name}</span>
                          ) : null}
                        </div>
                        <h4 onClick={() => navigate(`/news/${item.id}`)}>
                          {item.title.includes(
                            urlState.keyword.includes("%")
                              ? decodeURI(urlState.keyword)
                              : urlState.keyword
                          ) ? (
                            <>
                              {
                                item.title.split(
                                  urlState.keyword.includes("%")
                                    ? decodeURI(urlState.keyword)
                                    : urlState.keyword
                                )[0]
                              }
                              {/* 검색 키워드 강조 단어 */}
                              <strong>
                                {urlState.keyword.includes("%")
                                  ? decodeURI(urlState.keyword)
                                  : urlState.keyword}
                              </strong>
                              {
                                item.title.split(
                                  urlState.keyword.includes("%")
                                    ? decodeURI(urlState.keyword)
                                    : urlState.keyword
                                )[1]
                              }
                            </>
                          ) : (
                            item.title
                          )}
                        </h4>
                        {/* 검색 뉴스 내용 */}
                        <p>{item.contents ? item.contents.content : ""}</p>
                        <h6>
                          {item.published_at
                            ? moment(item.published_at).format("YYYY.MM.DD.")
                            : null}
                        </h6>
                      </div>

                      {/* TODO : 아이콘은 css 만 적용해두고 숨겨두기 */}
                      {/* <div className="iconbox">
                        <span onClick={() => func.likedNews(item.id)}>
                          <img
                            src={item.is_liked ? LikedHeart : Heart}
                            alt="하트 아이콘"
                          />
                        </span>
                      </div> */}
                    </li>
                  ))

                ) : (
                  <li className="news_none">뉴스가 없습니다.</li>
                )
              ) : (
                <li className="news_none">뉴스가 없습니다.</li>
              )}
            </ul>
          </div>





        </div>







      )}
      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </Container>
  );
};

export default PlayGround;
