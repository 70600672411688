import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";


// api
import { mainApi, paymentApi } from "Utils/api/api";

// components
import { Alert, Loading } from "Components";

// styles
import { Container } from "Styles/styles";

// logics
import MobiPaymentLogic from "./MobiPayment.logic.js";

const MobiPayment = ({ loading, setLoading, url }) => {


  const [state, setState] = useState({
    data_list: [],
    data_info: [],
    payment_info: [],
    child: [],
    modal: { open: false, type: "", id: "" },
    step: 1,
  });



  // const { state, data, func } = MobiPaymentLogic({
  //     loading,
  //     setLoading,
  //     // navigate,
  //     // url: urlState,
  // });

  const urlParams = new URLSearchParams(window.location.search);



  useEffect(() => {


    const code = urlParams.get("code");

    console.log("code : ", code);

    if (code === "FAILURE_TYPE_PG") {

      console.log("1");
      const paymentType = window.location.pathname.split('/')[2];

      setStateValue("alert", {
        title: "결제에 실패하였습니다.",
        sub: "다시 시도하여 주세요.",
        // isOk: () => window.location.href = `/login/student`,
        isOk: () => window.location.href = "/subscribe/payment/" + paymentType,
        isCancel: null,
      });

    } else {

      console.log("2");
      addPayment();

    }





  }, []);



  const addPayment = async (method) => {


    console.log("method check : ", method);


    try {

      const paymentId = urlParams.get("paymentId");
      const pgCode = urlParams.get("pgCode");
      const transactionType = urlParams.get("transactionType");
      const pgMessage = urlParams.get("pgMessage");
      const txId = urlParams.get("txId");
      const paymentType = window.location.pathname.split('/')[2];
      const childIds = urlParams.getAll('child');

      console.log("paymentId : ", paymentId);
      console.log("pgCode : ", pgCode);
      console.log("transactionType : ", transactionType);
      console.log("pgMessage : ", pgMessage);
      console.log("txId : ", txId);
      console.log("urlParams : ", urlParams);
      console.log("------------");
      console.log("paymentType : ", paymentType);
      console.log("childIds : ", childIds);



      const { data } = await paymentApi.newSetPayment(
        { id: paymentType },
        {
          child: childIds,
          paymentId: paymentId,
          txId: txId
        }
      );

      console.log("final response", data);

      if (data.code == "200") {

        setStateValue("alert", {
          title: "결제에 성공하였습니다.",
          sub: "이제 로그아웃 하신 후 자녀의 ID로 로그인하여 기사를 작성할 수 있습니다.",
          // isOk: () => window.location.href = `/login/student`,
          isOk: () => window.location.href = "/",
          // isOk: () => setStateValue("alert", null),
          isCancel: null,
        });

      }



    } catch (error) {

      console.log(error);

      setStateValue("alert", {
        title: "결제를 실패하였습니다.",
        sub: "error.response.data.message",
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });


    } finally {

      setLoading(false);

    }


  }

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <>
      {loading && <Loading loading={loading} />}
      <div>

      </div>
      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );


};

export default MobiPayment;