import { useRef, useState, useEffect } from "react";

import { userApi, checkApi } from "Utils/api/api";
import { isOkPw, isPhoneNum, scrollTop } from "Utils/lib/helper";
import moment from "moment";

const SignupLogic = ({ loading, setLoading, url }) => {
  const [state, setState] = useState({
    phone_send: null, // null / true / false
    phone_check: null, // null / true / false
    pw_err: "",
    pw_check: "",
    phone_err: "",
    login_id_check: null, // null / true / false
    nick_name_check: null, // null / true / false
    email_check: null, // null / true / false
    alert: null,
    step: 1,
    term_list: [],
    terms_all: false,
    city_list: [],
    alert: null,
    certification: "", // 휴대폰 인증
    parents_name: "",
  });
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    // 공통
    terms: [],
    name: "",
    password: "",
    passwordCheck: "", // 데이터에 안들어감.
    phone_number: "",
    code: "",
    birth: "",
    gender: "",
    city_id: "",
    // 학부모 (아이디)
    email: "",
    // 학생
    login_id: "",
    nick_name: "",
    grade: "",
  });
  useEffect(() => {
    getSignupTerms();
    scrollTop();
  }, []);
  useEffect(() => {
    scrollTop();
  }, [state.step]);
  useEffect(() => {
    resetValue();
    getSignupTerms();
  }, [url.type]);
  // input 값 리셋
  const resetValue = () => {
    setState({
      ...state,
      pw_err: "",
      pw_check: "",
      phone_err: "",
      step: 1,
      terms_all: false,
      connection: null,
      login_id_check: null,
      nick_name_check: null,
      email_check: null,
      alert: null,
    });
    setInputData({
      terms: [],
      name: "",
      password: "",
      passwordCheck: "",
      phone_number: "",
      birth: "",
      gender: "",
      city_id: "",
      email: "",
      login_id: "",
      nick_name: "",
      grade: "",
    });
  };

  // 비밀번호 확인
  useEffect(() => {
    pwCheck(inputData.password, inputData.passwordCheck);
  }, [inputData.password, inputData.passwordCheck]);

  // 약관 , 도시 조회
  const getSignupTerms = async () => {
    try {
      const { data } = await checkApi.getTerms({
        kind: "signup",
        type: url.type === "parents" ? "parents" : "child",
      });
      const { data: city } = await checkApi.getCtiy({
        id: 1,
      });
      console.log(data.data.service_list);
      setState({
        ...state,
        term_list: data.data.service_list,
        city_list: city.data.city.map((c) => {
          return { value: c.id, label: c.name };
        }),
        loading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 이름, 이메일, 닉네임 중복검사
  const checkInfoOk = async (type) => {
    if (inputData[`${type}`] === "") {
      setStateValue("alert", {
        title: `${
          type === "login_id"
            ? "아이디"
            : type === "nick_name"
            ? "닉네임"
            : "이메일"
        }을 입력하세요.`,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    const json = {
      login_id: inputData.login_id,
      email: inputData.email,
      nick_name: inputData.nick_name,
    };

    try {
      const { data } = await checkApi.checkInfo(
        {
          type: type,
        },
        json
      );
      setState({
        ...state,
        [`${type}_check`]: true,
      });
    } catch (error) {
      console.log(error);
      setState({
        ...state,
        [`${type}_check`]: false,
      });
    } finally {
      setLoading(false);
    }
  };
  // 휴대폰 인증
  const checkPhoneOk = async (type) => {
    if (type === "send") {
      if (inputData.phone_number === "") {
        setStateValue("alert", {
          title: `휴대폰 번호를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!isPhoneNum(inputData.phone_number)) {
        setStateValue("alert", {
          title: `올바른 휴대폰 번호를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        phone: inputData.phone_number,
      };
      try {
        const { data } = await checkApi.sendPhoneCheck(
          {
            kind: "signup",
            type: url.type === "student" ? "child" : "parents",
          },
          json
        );
        setState({
          ...state,
          phone_send: true,
        });
      } catch (error) {
        
        console.log(error);

        setStateValue("alert", {
          // title: "인증번호가 맞지 않습니다.",
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      if (inputData.code === "") {
        setStateValue("alert", {
          title: `인증 코드를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        phone: inputData.phone_number,
        code: inputData.code,
      };
      try {
        const { data } = await checkApi.checkPhoneVerify(
          {
            kind: "signup",
            type: url.type === "student" ? "child" : "parents",
          },
          json
        );
        console.log(data);
        if (data.data.family) {
          setState({
            ...state,
            step: 3,
            parents_name: data.data.family.name,
          });
        } else {
          setState({
            ...state,
            step: 3,
          });
        }
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // 비밀번호 검증
  const pwCheck = (pw, pw_check) => {
    let result = true;
    let message = "";
    if (pw === "") {
      result = false;
      message = null;
    } else if (pw.length < 8) {
      result = false;
      message = "비밀번호가 너무 짧습니다.";
    } else if (pw.length > 20) {
      result = false;
      message = "비밀번호가 너무 깁니다.";
    } else if (isOkPw(pw)) {
      result = false;
      message =
        "영문(대/소문자), 숫자, 특수문자[! @ # $ % ^ & *] 중 2가지 이상을 포함한 비밀번호를 입력하여 주십시오";
    } else if (pw_check === "") {
      result = false;
      message = "비밀번호 확인을 입력하세요.";
    } else if (pw !== pw_check) {
      result = false;
      message = "비밀번호가 일치하지 않습니다.";
    } else if (pw === pw_check) {
      result = true;
      message = null;
    }
    setState({ ...state, pw_check: result, pw_err: message });
  };
  const signup = async () => {
    if (inputData.name === "") {
      setStateValue("alert", {
        title: "이름을 입력하여 주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (url.type === "student") {
      if (!state.nick_name_check) {
        setStateValue("alert", {
          title: "닉네임 중복확인을 해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!state.login_id_check) {
        setStateValue("alert", {
          title: "아이디 중복확인을 해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
    }
    if (url.type === "parents") {
      if (!state.email_check) {
        setStateValue("alert", {
          title: "이메일 중복확인을 해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
    }
    if (inputData.password === "") {
      setStateValue("alert", {
        title: "비밀번호를 입력하여 주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (inputData.passwordCheck === "") {
      setStateValue("alert", {
        title: "비밀번호 확인을 입력하여 주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (inputData.password !== inputData.passwordCheck) {
      setStateValue("alert", {
        title: "비밀번호와 비밀번호 확인이 같지 않습니다. 확인바랍니다.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (inputData.city_id === "") {
      setStateValue("alert", {
        title: "주소 지역을 선택해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (inputData.birth === "") {
      setStateValue("alert", {
        title: "생년월일을 입력해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (url.type === "student") {
      if (inputData.grade === "") {
        setStateValue("alert", {
          title: "학년을 선택해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
    }
    if (inputData.gender === "") {
      setStateValue("alert", {
        title: "성별을 선택해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    const studentJson = {
      name: inputData.name,
      nick_name: inputData.nick_name,
      login_id: inputData.login_id,
      password: inputData.password,
      birth: moment(inputData.birth).format("YYYY-MM-DD"),
      grade: inputData.grade,
      gender: inputData.gender,
      phone_number: inputData.phone_number,
      city_id: inputData.city_id,
      services: inputData.terms,
    };
    const parentJson = {
      name: inputData.name,
      login_id: inputData.email,
      password: inputData.password,
      birth: moment(inputData.birth).format("YYYY-MM-DD"),
      gender: inputData.gender,
      phone_number: inputData.phone_number,
      city_id: inputData.city_id,
      services: inputData.terms,
    };
    try {
      const { data } = await userApi.signup(
        { type: url.type === "student" ? "child" : "parents" },
        url.type === "student" ? studentJson : parentJson
      );
      setStateValue("step", 4);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  ////////////////  기능  ////////////////
  // state 셋팅
  const setStateValue = (key, value) => {
    if (key === "terms_all") {
      if (value) {
        setInputData({
          ...inputData,
          terms: state.term_list.map((item) => {
            return item.id;
          }),
        });
      }
      setState({ ...state, [key]: value });
    } else {
      setState({ ...state, [key]: value });
    }
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    if (key === "terms") {
      if (inputData.terms.filter((item) => item === value).length > 0) {
        setInputData({
          ...inputData,
          terms: inputData.terms.filter((item) => item !== value),
        });
        setState({ ...state, terms_all: false });
      } else {
        setInputData({ ...inputData, terms: inputData.terms.concat(value) });
      }
    } else if (key === "nick_name") {
      setInputData({ ...inputData, [key]: value });
      setState({ ...state, nick_name_check: null });
    } else if (key === "login_id") {
      setInputData({ ...inputData, [key]: value });
      setState({ ...state, login_id_check: null });
    } else if (key === "email") {
      setInputData({ ...inputData, [key]: value });
      setState({ ...state, email_check: null });
    } else {
      setInputData({ ...inputData, [key]: value });
    }
  };

  // state step 셋팅
  const setStep = (value) => {
    if (value === 2) {
      // 필수 동의 검증
      const isRequired = state.term_list.filter((i) => i.is_required === true);
      if (
        isRequired.filter((i) => inputData.terms.includes(i.id)).length ===
        isRequired.length
      ) {
        setState({ ...state, step: value });
      } else {
        setStateValue("alert", {
          title: "필수 동의 항목은 동의를 하셔야 가입 진행이 가능합니다.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
    } else if (value === 4) {
      if (value === "email") {
        if (!state.name_check || inputData.name === "") {
          alert("이름 입력을 확인 바랍니다.");
          return;
        } else {
          setState({ ...state, step: value });
        }
        // // 휴대폰 검증 => 현재 기능 없음 클릭시 다음 단계로 넘김
        // if (state.phone_check) {
        //   setState({ ...state, step: value });
        // } else {
        //   alert("휴대폰 인증이 완료되지 않았습니다.");
        // }
        // } else {
        //   setState({ ...state, step: value });
        // }
      } else {
        setState({ ...state, step: value });
      }
    }
    setState({ ...state, step: value });
  };

  return {
    state,
    data: { inputData },
    ref: {},
    func: {
      setStateValue,
      setInputStateValue,
      signup,
      setStep,
      checkInfoOk,
      checkPhoneOk,
    },
  };
};

export default SignupLogic;
