import React, { useState, useEffect } from "react";

// utils
import { userApi, checkApi } from "Utils/api/api";

//lib
import { isEmail, isOkPw, isPhoneNum } from "Utils/lib/helper";

// json

// style

const SignupLogic = ({ loading, setLoading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    loading: true,
    step: 1,
    list_data: [],
    terms_list: [],
    modal: {
      open: false,
    },
    find_err: null,
    find_id: "",
    name_check: true,
    phone_check: true,
    email_check: true,
    type: "",
    identity_ok: false, // 본인인증
    alert: null,
    pw_err: "",
    pw_check: "",
    phone_send: null,
    phone_check: null,
    find_id: "",
  });
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    name: "",
    phone_number: "",
    code: "",
    login_id: localStorage.getItem("login_id"),
    // 비밀번호 변경
    pw: "",
    pw_check: "",
  });
  // 비밀번호 확인
  useEffect(() => {
    pwCheck(inputData.pw, inputData.pw_check);
  }, [inputData.pw, inputData.pw_check]);

  useEffect(() => {}, [url]);

  // 데이터 list 관리
  const dataList = {};

  ////////////// API //////////////
  // 휴대폰 인증 검증
  const checkPhoneOk = async (type) => {
    if (type === "send") {
      if (inputData.phone_number === "") {
        setStateValue("alert", {
          title: `휴대폰 번호를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!isPhoneNum(inputData.phone_number)) {
        setStateValue("alert", {
          title: `올바른 휴대폰 번호를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        name: inputData.name,
        phone: inputData.phone_number,
        login_id: inputData.login_id,
        email: inputData.login_id
      };
      try {
        const { data } = await checkApi.sendPhoneCheck(
          {
            kind: url.location === "id" ? "id" : "password",
            type: url.type === "student" ? "child" : "parents",
          },
          json
        );
        setState({
          ...state,
          phone_send: true,
        });
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: "입력하신 정보의 계정을 찾을 수 없습니다.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      if (inputData.code === "") {
        setStateValue("alert", {
          title: `인증 코드를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        login_id: inputData.login_id,
        email: inputData.login_id,
        name: inputData.name,
        phone: inputData.phone_number,
        code: inputData.code,
      };
      try {
        const { data } = await checkApi.checkPhoneVerify(
          {
            kind: url.location === "id" ? "id" : "password",
            type: url.type === "student" ? "child" : "parents",
          },
          json
        );
        if (url.location === "id") {
          setState({
            ...state,
            phone_check: true,
            step: 2,
            find_id: data.data[url.type === "student" ? "login_id" : "email"],
          });
        } else {
          setState({
            ...state,
            phone_check: true,
            step: 2,
          });
          // navigate("/find/pw/change");
        }
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  const changePw = async () => {
    const json = {
      name: inputData.name,
      login_id: inputData.login_id,
      email: inputData.login_id,
      phone_number: inputData.phone_number,
      password: inputData.pw,
    };
    try {
      const { data } = await userApi.setPw(
        { type: url.type === "student" ? "child" : url.type },
        json
      );
      setStateValue("alert", {
        title: "비밀번호 재설정이 완료되었습니다.",
        sub: "새로운 비밀번호로 로그인 하시길 바랍니다.",
        isOk: () => (window.location.href = `/login/${url.type}`),
        isCancel: null,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      // TODO : find_err 설정 필요.
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 비밀번호 검증
  const pwCheck = (pw, pw_check) => {
    let result = true;
    let message = "";
    if (pw === "") {
      result = false;
      message = null;
    } else if (pw.length < 8) {
      result = false;
      message = "비밀번호가 너무 짧습니다.";
    } else if (pw.length > 20) {
      result = false;
      message = "비밀번호가 너무 깁니다.";
    } else if (isOkPw(pw)) {
      result = false;
      message =
        "영문(대/소문자), 숫자, 특수문자[! @ # $ % ^ & *] 중 2가지 이상을 포함한 비밀번호를 입력하여 주십시오";
    } else if (pw_check === "") {
      result = false;
      message = "비밀번호 확인을 입력하세요.";
    } else if (pw !== pw_check) {
      result = false;
      message = "비밀번호가 일치하지 않습니다.";
    } else if (pw === pw_check) {
      result = true;
      message = null;
    }
    setState({ ...state, pw_check: result, pw_err: message });
  };
  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    if (key === "name") {
      setInputData({ ...inputData, name: value });
      setState({ ...state, name_check: true, err_message: "" });
    } else if (key === "phone_num") {
      setInputData({ ...inputData, phone_num: value });
      setState({ ...state, phone_check: true });
    } else if (key === "email") {
      if (!isEmail(value)) {
        setState({
          ...state,
          email_check: false,
          err_message: "이메일 형식의 아이디를 올바르게 입력바랍니다.",
        });
        setInputData({
          ...inputData,
          email: value,
        });
        return;
      } else {
        setInputData({ ...inputData, email: value });
        setState({
          ...state,
          email_check: true,
          err_message: "",
        });
      }
    } else {
      setInputData({ ...inputData, [key]: value });
    }
  };
  // state step 셋팅
  const setStep = (value) => {
    setState({ ...state, step: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
  };

  return {
    state,
    data: { dataList, inputData },
    func: {
      setStateValue,
      setInputStateValue,
      setModalData,
      setStep,
      changePw,
      checkPhoneOk,
    },
  };
};

export default SignupLogic;
