import React, { useState, useEffect } from "react";

// api
import { userApi, checkApi } from "Utils/api/api";

//lib
import { setFormData, isPhoneNum } from "Utils/lib/helper";

// img

const AccountLogic = ({ loading, setLoading, url, navigate }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    loading: true,
    child_loading: true,
    detail_info: [],
    detail_child_info: [],
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
    alert: null,
    city_list: [],
    pw_err: null,
    phone_send: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    // 공통
    name: "",
    login_id: "",
    city_id: "",
    birth: "",
    gender: "",
    // 학생
    nick_name: "",
    grade: "",
    parents_phone_num: "",
    img_src: null, // 프로필 이미지
    img_url: null, // 프로필 이미지
    // 비밀번호 변경
    pw: "",
    // 학부모가 학생 정보 변경할때
    child_city_id: "",
    child_birth: "",
    child_gender: "",
    // 휴대폰 번호 변경
    phone_number: "",
    code: "",
    intro: ""
  });

  useEffect(() => {
    if (url.type === "children") {
      getChildDetail();
    } else {
      getDetail();
    }
  }, [url]);
  useEffect(() => {
    if (url.type === "children") {
      getChildDetail();
    } else {
      getDetail();
    }
  }, []);

  ////////////////  API  ////////////////
  // 상세 조회 & 도시 조회
  const getDetail = async () => {

    console.log("----->>");

    try {
      const { data: city } = await checkApi.getCtiy({
        id: 1,
      });
      const {
        data: { data },
      } = await userApi.getMyInfo();
      setState({
        ...state,
        detail_info: data,
        loading: false,
        city_list: city.data.city.map((c) => {
          return { value: c.id, label: c.name };
        }),
      });
      setInputData({
        ...inputData,
        name: data.user.name,
        nick_name: data.user.type === "child" ? data.user.nick_name : null,
        login_id: data.user.login_id,
        parents_phone_num: "",
        city_id: data.user.cities.id,
        gender: data.user.gender,
        grade: data.user.grade,
        birth: data.user.birth.replaceAll("-", "").substring(2, 8),
        img_src: data.user.profile_image_url,
        img_url: data.user.profile_image_url,
        intro : data.user.intro,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getChildDetail = async () => {
    try {
      const {
        data: { data },
      } = await userApi.getMyChildInfo({ id: url.id });
      setState({
        ...state,
        detail_child_info: data,
        child_loading: false,
      });
      setInputData({
        ...inputData,
        child_city_id: data.family.cities.id,
        child_birth: data.family.birth,
        child_gender: data.family.gender,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 비밀번호 변경
  const changePw = async () => {
    if (!inputData.pw || inputData.pw === "") {
      setStateValue("pw_err", "비밀번호를 입력하여 주세요.");
      return;
    }
    // 로그인
    try {
      const json = {
        login_id: state.detail_info.user.login_id,
        password: inputData.pw,
      };
      try {
        const {
          data: { data },
        } = await userApi.login(json);
        window.location.href = "/find/pw/change";
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 회원 탈퇴
  const withdrawal = async () => {
    try {
      const {
        data: { data },
      } = await userApi.leaveUs();
      localStorage.clear();
      setStateValue("alert", {
        title: "탈퇴가 완료되었습니다.",
        sub: null,
        isOk: () => (window.location.href = "/"),
        isCancel: null,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 휴대폰 인증
  const checkPhoneOk = async (type) => {
    if (type === "send") {
      if (inputData.phone_number === "") {
        setStateValue("alert", {
          title: `휴대폰 번호를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!isPhoneNum(inputData.phone_number)) {
        setStateValue("alert", {
          title: `올바른 휴대폰 번호를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        old_phone: state.detail_info.user.phone_number,
        phone: inputData.phone_number,
      };
      try {
        const { data } = await checkApi.sendPhoneCheck(
          {
            kind: "edit",
            type: url.type === "student" ? "child" : "parents",
          },
          json
        );
        setState({
          ...state,
          phone_send: true,
        });
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: "인증번호가 맞지 않습니다.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      if (inputData.code === "") {
        setStateValue("alert", {
          title: `인증 코드를 입력하세요.`,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        phone: inputData.phone_number,
        code: inputData.code,
      };
      try {
        const { data } = await checkApi.checkPhoneVerify(
          {
            kind: "edit",
            type: url.type === "student" ? "child" : "parents",
          },
          json
        );
        setState({
          ...state,
          alert: {
            title: "휴대폰 번호가 변경되었습니다.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          },
        });
        getDetail();
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  // 정보 저장 변경
  const changeInfo = async () => {
    if (localStorage.getItem("type") === "child") {
      if (inputData.img_url) {
        const profileform = setFormData({
          image: inputData.img_url,
        });
        try {
          const { data } = await userApi.editProfileImage(profileform);
          console.log(data);
          const profileBefore = localStorage.getItem("user_profile");
          localStorage.setItem(
            "user_profile",
            data.data.profile_image_url
              ? data.data.profile_image_url
              : profileBefore
          );
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      }
    }
    try {
      const json = {
        name: inputData.name,
        city_id: inputData.city_id,
        birth: inputData.birth,
        gender: inputData.gender,
        nick_name: inputData.nick_name,
        grade: inputData.grade,
        intro: inputData.intro,

      };
      try {
        const {
          data: { data },
        } = await userApi.editMyInfo(json);
        setStateValue("alert", {
          title: "저장이 완료됐어요!",
          sub: null,
          isOk: () => (window.location.href = "/account/info/0"),
          isCancel: null,
        });
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 자녀 정보 저장 변경
  const changeChildInfo = async () => {
    try {


      const json = {
        city_id: inputData.child_city_id,
        birth: inputData.child_birth,
        gender: inputData.child_gender,
      };

      console.log("json : ", json);

      try {
        const {
          data: { data },
        } = await userApi.editMyChildInfo({ id: url.id }, json);
        setStateValue("alert", {
          title: "저장이 완료됐어요!",
          sub: null,
          isOk: () => (window.location.href = "/account/info/0"),
          isCancel: null,
        });
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    setInputData({ ...inputData, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { inputData },
    func: {
      setStateValue,
      setInputStateValue,
      setInputData,
      setModalData,
      paging,
      changePw,
      changeInfo,
      withdrawal,
      changeChildInfo,
      checkPhoneOk,
    },
  };
};

export default AccountLogic;
