import React, { useState, useEffect } from "react";

// api
import { mainApi } from "Utils/api/api";

const PlayGroundLogic = ({ loading, setLoading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_loading: true,
    
    data_list: [],
    data_list2: [],

    data_week1: [],
    data_week2: [],
    data_week3: [],
    data_week4: [],

    data_student_week1: [],
    
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
    grade_type: 1, // 1학년, 2학년, 3~4학년, 5~6학년
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (url.type === "main") {
      getList();
    } else if (url.type === "search") {
      
      getSearchList();
    }
  }, [url, state.search_keyword, currentPage, state.grade_type]);

  // 데이터 list 관리
  const dataList = {};
  const dataList2 = {};

  ////////////////  API  ////////////////
  // 목록 조회
  const getList = async () => {
    try {
      const {
        data: { data },
      } = await mainApi.getNewsList({
        grade: state.grade_type,
        page: currentPage - 1,
      });

      setState({
        ...state,
        data_list: data,
        data_week1: data.week1,
        data_week2: data.week2,
        data_week3: data.week3,
        data_week4: data.week4,
        data_student_week1: data.student_week1,
      });

    } catch (error) {

      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });



      console.log("error.response.data.code : ", error.response.data.code);

      if(error.response.data.code == 401){

        console.log("please log out");   
      }


    } finally {
      setLoading(false);
    }
  };



  // 검색 목록 조회
  const getSearchList = async () => {


    try {

      const {
        data: { data:data1 },
      } = await mainApi.getNewsSearchList({
        search: url.keyword.includes("%")
          ? decodeURI(url.keyword)
          : url.keyword,
        page: currentPage - 1,
      });


      /*  
      const {
        data: { data:data2 },
      } = await mainApi.getUserNewsSearchList({
        search: url.keyword.includes("%")
          ? decodeURI(url.keyword)
          : url.keyword,
        page: currentPage - 1,
      });
      */

      console.log("data1 : ", data1);
      // console.log("data2 : ", data2);



      setState({
        ...state,
        data_list: data1,
        // data_list2: data2,
        // search_keyword : url.keyword,
      });


    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 좋아요
  const likedNews = async (id) => {

    if (localStorage.getItem("user_token")) {
      try {
        const { data } = await mainApi.setLikeNews({
          id: id,
        });
        if (url.type === "main") {
          getList();
        } else {
          getSearchList();
        }
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setStateValue("alert", {
        title: "좋아요는 회원만 가능 합니다.",
        sub: "로그인 하시러 가시겠습니까?",
        isOk: () => (window.location.href = "/login/student"),
        isCancel: () => setStateValue("alert", null),
      });
    }
  };

  // keyword로 검색 결과 셋팅
  const searchList = (keyword) => {

    state.search_keyword = keyword;

    if (state.search_keyword === "") {
      setStateValue("alert", {
        title: "키워드를 입력 바랍니다.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    } else {


      console.log("state.search_keyword : ", state.search_keyword);

      window.location.href = `/playground/search/${state.search_keyword}`;
      // getSearchList();
    }

  };

  // state 셋팅
  const setStateValue = (key, value) => {
    if (key === "search_keyword") {
      if (!value) {
        document.querySelector("#search").value = "";
        setState({ ...state, [key]: value });
      } else {
        setState({ ...state, [key]: value });
      }
    } else {
      setState({ ...state, [key]: value });
    }
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { dataList, currentPage },
    func: { setStateValue, setModalData, paging, searchList, likedNews },
  };
};

export default PlayGroundLogic;
