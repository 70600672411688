import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { Alert, Loading } from "Components";



// Img
import Heart from "Assets/images/icons/playground_heart.svg";
import LikedHeart from "Assets/images/icons/playground_like_heart.svg";


// styles
import { Container } from "Styles/styles";
import { QuestionSt, StyleContainer } from "Styles/styles";


// logics
import UserNewsListLogic from "./UserNewsList.logic.js";
import moment from "moment";


const UserNewsList = ({ loading, setLoading }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [urlState, setUrlState] = useState({
        current: "",
        type: "",
    });

    const { state, data, func } = UserNewsListLogic({
        loading,
        setLoading,
        navigate,
        url: urlState,
    });

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0].replace(/-/g,".");
    };

    useEffect(() => {
        setUrlState({
            ...urlState,
            current: location.pathname,
        });
    }, [location.pathname]);

    /*  
    useEffect(() => {
        setUrlState({
        ...urlState,
        current: location.pathname,
        id: location.pathname.split("/news/")[1].split("/")[0],
        });
    }, [location.pathname]);
    */
    

    console.log(location.pathname.split('/')[2]);

    return (
        <>
          {loading && <Loading loading={loading} />}
    
          <Container>

          <StyleContainer>




    <div className="userNewsList">
      <div className="header">     
          
          <div>
            <img class="profile" src={state.user_profile_image_url} />
          </div>
          <div class="cont-box">
            <div class="nick_name">{state.user_nick_name}</div>
            <div class="intro">{state.intro}</div>
          </div>

      </div>
      <div className="bottom">
            <ul>
            {state.data_news.map((item) => (
                  // <li onClick={() => navigate(`/news/${item.id}`)}>
                  <li>
                    <div class="img-box">
                      <img src={item.images.image_url} />
                    </div>
                    <div class="content-box">
                      <div className="title">
                      {item.title}
                      </div>
                      <div className="content">
                      {item.contents[0].content.replace(/<\/?[^>]+(>|$)/g, "")}
                      </div>
                      <div className="user-box">


                        <img className="profile" src={item.users.profile_image_url} />
                      

                        <span className="username">{item.users.nick_name}</span>
                          <span className="date">
                          {formatDate(item.created_at)}    
                          </span>
                        <div className="iconbox">
                          <span onClick={(e) => func.likedNews(item.id,e)}>
                            <img
                              src={item.is_liked ? LikedHeart : Heart}
                              alt="하트 아이콘"
                            />
                          </span>
                        </div>


                      </div>


                    </div>
                  </li>
            ))}
            </ul>
          </div>
          {/*  
          <pre>
            {JSON.stringify(state, null, 2)}
          </pre>
          */}
        </div>          
          
          </StyleContainer>


      {/*  얼럿 */}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}


        </Container>
        </>
      );


};

export default UserNewsList;