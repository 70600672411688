import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// json
import jsonData from "Assets/contents/contents.json";
import News01 from "Assets/images/icons/landing_news01.svg";

// components
import { Alert } from "Components";
// img
import NewsEX from "Assets/images/icons/playground_ex.png";
import Mail from "Assets/images/icons/playground_mail.png";
import Talk from "Assets/images/icons/playground_talk.png";
import NewsbuilderSymbol from "Assets/images/icons/newsbuilder_symbol.svg";
import Share from "Assets/images/icons/playground_share_icon.svg";
import Heart from "Assets/images/icons/playground_heart.svg";
import LikedHeart from "Assets/images/icons/playground_like_heart.svg";
import Close from "Assets/images/icons/close2.svg";

// styles
import { Container } from "Styles/styles";

// logics
import CommunityLogic from "./Community.logic";
import moment from "moment";

const Community = ({ loading, setLoading }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [urlState, setUrlState] = useState({
        current: "",
        type: "",
        keyword: "",
    });
    const { state, data,data2, func } = CommunityLogic({
        loading,
        setLoading,
        navigate,
        url: urlState,
    });


    useEffect(() => {
        setUrlState({
            ...urlState,
            current: location.pathname,
        });

    }, [location.pathname]);


    return (
        <Container>
            뉴스 놀이터
            <>

                <div className="playground community">
                    <div className="playround_title">
                        <div>
                            <img src={Mail} alt="메일 아이콘" />
                            <img src={NewsbuilderSymbol} alt="뉴스빌더심볼 아이콘" />
                            <img src={Talk} alt="말풍선 아이콘" />
                            <h2>
                                <span>뉴스 놀이터</span> 최신 뉴스
                            </h2>
                            <p>
                                매주 업데이트 되는 뉴스를 읽으며 문해력과 시사상식을 높이고{" "}
                                <br />
                                댓글을 쓰고 읽으며 생각을 나눠요!
                            </p>
                        </div>
                    </div>




                    <div className="news">
                        <h2>
                            <span>
                                랭킹  
                            </span>
                            뉴스
                        </h2>
                        <ul>

                

                            {state.data_ranking ? (
                                state.data_ranking.student_week1 &&
                                    state.data_ranking.student_week1.length > 0 ? (
                                    state.data_ranking.student_week1.map((item, i) => (


                                        i === 0 ?

                                            <li className="main-news"
                                                onClick={() => navigate(`/news/${item.id}`)}
                                                style={{
                                                    backgroundImage: `
                            linear-gradient(rgba(0, 0, 0, 0.19), 
                            rgba(0, 0, 0, 0.19)),
                            url(${item.images.image_url})`
                                                }} key={`news_${item.id}`}>

                                                <div class="title-box">


                                                    <div>
                                                        <div className="tagbox"
                                                            onClick={() => navigate(`/news/${item.id}`)}>
                                                            {item.first_categories ? (
                                                                <span>{item.first_categories.category_name}</span>) : null}
                                                            {item.second_categories ? (
                                                                <span>{item.second_categories.category_name}</span>) : null}
                                                        </div>
                                                        <h4>
                                                            {item.title}
                                                        </h4>
                                                    </div>
                                                    <div className="username-box">
                                                        <img className="profile" src={item.users.profile_image_url} />
                                                        <span
                                                            onClick={() => navigate(`/users/${item.users.id}/news-list`)}
                                                            className="username">
                                                            {item.users.nick_name}</span>
                                                        <span className="date">
                                                            {item.published_at
                                                                ? moment(item.published_at).format("YYYY.MM.DD.")
                                                                : null}
                                                        </span>
                                                        <div className="iconbox">
                                                            <span onClick={() => func.likedNews(item.id)}>
                                                                <img
                                                                    src={item.is_liked ? LikedHeart : Heart}
                                                                    alt="하트 아이콘"
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            :

                                            <li
                                                className="sub-news"
                                                key={`news_${item.id}`}>



                                                <div className="cont-box">
                                                    <div
                                                        className="tagbox"
                                                        onClick={() => navigate(`/news/${item.id}`)}
                                                    >
                                                        {item.first_categories ? (
                                                            <span>{item.first_categories.category_name}</span>
                                                        ) : null}
                                                        {item.second_categories ? (
                                                            <span>{item.second_categories.category_name}</span>
                                                        ) : null}
                                                    </div>
                                                    <h4 onClick={() => navigate(`/news/${item.id}`)}>
                                                        {item.title}
                                                    </h4>

                                                    <h6>

                                                        {/* <pre>
                          {JSON.stringify(item, null, 2)}
                          </pre> */}

                                                        <div className="username-box">
                                                            <img className="profile" src={item.users.profile_image_url} />
                                                            <span
                                                                onClick={() => navigate(`/users/${item.users.id}/news-list`)}
                                                                className="username">
                                                                {item.users.nick_name}</span>

                                                            <span className="date">
                                                                {item.published_at
                                                                    ? moment(item.published_at).format("YYYY.MM.DD.")
                                                                    : null}
                                                            </span>


                                                            <div className="iconbox">
                                                                <span onClick={() => func.likedNews(item.id)}>
                                                                    <img
                                                                        src={item.is_liked ? LikedHeart : Heart}
                                                                        alt="하트 아이콘"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </h6>

                                                </div>






                                                {item.images && item.images.image_url ? (

                                                    <div
                                                        className="news-image-box"

                                                        style={{
                                                            backgroundImage: `
                                url(${item.images.image_url})`
                                                        }}
                                                    >
                                                    </div>
                                                ) : (
                                                    <div
                                                        onClick={() => navigate(`/news/${item.id}`)}
                                                        className="no_image"
                                                    ></div>
                                                )}
                                            </li>

                                    ))
                                ) : (
                                    <li className="news_none">뉴스가 없습니다.1</li>
                                )
                            ) : (
                                <li className="news_none">뉴스가 없습니다.2</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="playground ournews">
                    <div className="tab">
                        <span
                            className={state.grade_type === 1 ? "on" : ""}
                            onClick={() => func.setStateValue("grade_type", 1)}
                        >
                            1학년
                        </span>
                        <span
                            className={state.grade_type === 2 ? "on" : ""}
                            onClick={() => func.setStateValue("grade_type", 2)}
                        >
                            2학년
                        </span>
                        <span
                            className={state.grade_type === "3" ? "on" : ""}
                            onClick={() => func.setStateValue("grade_type", "3")}
                        >
                            3 · 4학년
                        </span>
                        <span
                            className={state.grade_type === "5" ? "on" : ""}
                            onClick={() => func.setStateValue("grade_type", "5")}
                        >
                            5 · 6학년
                        </span>
                    </div>

                    <div className="news">
                        <h2>
                            <span>
                                우리의
                            </span>
                            뉴스
                        </h2>
                        <ul>
                            {state.data_student_week1 ? (
                                state.data_student_week1.student_week1 &&
                                    state.data_student_week1.student_week1.length > 0 ? (
                                    state.data_student_week1.student_week1.map((item) => (
                                        <li key={`news_${item.id}`}>
                                            {item.images && item.images.image_url ? (
                                                <img
                                                    onClick={() => navigate(`/news/${item.id}`)}
                                                    src={item.images.image_url}
                                                    alt="이번주 뉴스 이미지"
                                                />
                                            ) : (
                                                <div
                                                    onClick={() => navigate(`/news/${item.id}`)}
                                                    className="no_image"
                                                ></div>
                                            )}
                                            <div
                                                className="tagbox"
                                                onClick={() => navigate(`/news/${item.id}`)}
                                            >
                                                {item.first_categories ? (
                                                    <span>{item.first_categories.category_name}</span>
                                                ) : null}
                                                {item.second_categories ? (
                                                    <span>{item.second_categories.category_name}</span>
                                                ) : null}
                                            </div>
                                            <h4 onClick={() => navigate(`/news/${item.id}`)}>
                                                {item.title}
                                            </h4>
                                            <h6>

                                                {/* <pre>
              {JSON.stringify(item, null, 2)}
              </pre> */}

                                                <div className="username-box">
                                                    <img className="profile" src={item.users.profile_image_url} />
                                                    <span
                                                        onClick={() => navigate(`/users/${item.users.id}/news-list`)}
                                                        className="username">
                                                        {item.users.nick_name}</span>

                                                    <span className="date">
                                                        {item.published_at
                                                            ? moment(item.published_at).format("YYYY.MM.DD.")
                                                            : null}
                                                    </span>


                                                    <div className="iconbox">
                                                        <span onClick={() => func.likedNews(item.id)}>
                                                            <img
                                                                src={item.is_liked ? LikedHeart : Heart}
                                                                alt="하트 아이콘"
                                                            />
                                                        </span>
                                                    </div>

                                                </div>

                                            </h6>


                                        </li>
                                    ))
                                ) : (
                                    <li className="news_none">뉴스가 없습니다.1</li>
                                )
                            ) : (
                                <li className="news_none">뉴스가 없습니다.2</li>
                            )}
                        </ul>
                    </div>

                </div>


            </>
            {/*  얼럿 */}
            {state.alert ? (
                <Alert
                    title={state.alert.title}
                    sub={state.alert.sub}
                    isOk={state.alert.isOk}
                    isCancel={state.alert.isCancel}
                />
            ) : null}
        </Container>
    );
};

export default Community;
