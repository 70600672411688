import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment/moment";
import { toJpeg } from "html-to-image";


// api
import { lmsCommonApi, lmsChildApi } from "Utils/api/api";

//lib
import { setFormData } from "Utils/lib/helper";

// img

const BuilderLogic = ({ loading, setLoading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_loading: true,
    user_list: [],
    search_keyword: "", // 이미지 풀 조회
    image_upload: false, // 이미지 직접 업로드
    modal: { open: false, type: "", id: "" },
    news_year: parseInt(moment().format("YYYY")),
    news_month: parseInt(moment().format("MM")),
  });
  const [currentPage, setCurrentPage] = useState(1);
  // filter 관리
  const [filterList, setFilterList] = useState({
    first_category: [],
    second_category: [],
    image_list: [],
    image_total: [],
    news_list: [],
    news_total: [],
    year_list: [...Array(new Date().getFullYear() - 2023 + 1)].map((i, idx) => {
      return {
        value: 2023 + idx,
        label: `${2023 + idx} 년`,
      };
    }),
    month_list: [...Array(12)].map((i, idx) => {
      return {
        value: idx + 1,
        label: `${idx + 1} 월`,
      };
    }),
  });
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({

    tooltip1: true,   
    tooltip2: false,
    tooltip3: false,
    tooltip4: false,
    tooltip5: false,


    // 공통
    title: "",
    // news
    first_category: null,
    second_category: null,
    image_id: null,
    image_id_url: null,
    content: "",
    news: [],
    // letter
    content_01_id: null,
    image_01_id: null,
    image_01_id_url: null,
    title_01: "",
    content_01: "",

    content_02_id: null,
    image_02_id: null,
    image_02_id_url: null,
    title_02: "",
    content_02: "",

    content_03_id: null,
    image_03_id: null,
    image_03_id_url: null,
    title_03: "",
    content_03: "",

    content_04_id: null,
    image_04_id: null,
    image_04_id_url: null,
    title_04: "",
    content_04: "",

    content_05_id: null,
    image_05_id: null,
    image_05_id_url: null,
    title_05: "",
    content_05: "",
    letter: [],
  });
  const ref = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("type") === "child") {
      getCategoryList();
    } else {
      if (url.id) {
        getTempData(url.id);
      }
    }
  }, [url.id, url.type]);

  useEffect(() => {
    if (state.modal.type === "picture_select") {
      getImageList();
    } else if (state.modal.type === "my_news") {
      getmyNewsList();
    }
  }, [state.modal, state.news_year, state.news_month, currentPage]);


  // 데이터 list 관리
  const dataList = {};

  ////////////////  API  ////////////////
  // 임시 저장, 저장 데이터 불러오기
  const getTempData = async (id) => {
    if (url.type === "news" && id !== "null" && id !== undefined) {
      try {
        const {
          data: { data },
        } = await lmsCommonApi.getNewsDetail({
          id: id,
        });
        console.log(data);
        setInputData({
          ...inputData,
          first_category: data.news.first_categories
            ? data.news.first_categories.id
            : null,
          second_category: data.news.second_categories
            ? data.news.second_categories.id
            : null,
          image_id: data.news.contents ? data.news.contents[0].images.id : null,
          image_id_url: data.news.contents
            ? data.news.contents[0].images.image_url
            : null,
          title: data.news.title,
          content: data.news.contents ? data.news.contents[0].content : "",
          news: data.news,
        });
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else if (url.type === "newsletter" && id !== "null" && id !== undefined) {
      try {
        const {
          data: { data },
        } = await lmsCommonApi.getNewsLetterDetail({
          id: id,
        });
        const arr_01 = data.contents.filter(
          (i) => i.contents.layout_number === 1
        );
        const arr_02 = data.contents.filter(
          (i) => i.contents.layout_number === 2
        );
        const arr_03 = data.contents.filter(
          (i) => i.contents.layout_number === 3
        );
        const arr_04 = data.contents.filter(
          (i) => i.contents.layout_number === 4
        );
        const arr_05 = data.contents.filter(
          (i) => i.contents.layout_number === 5
        );
        console.log();
        setInputData({
          ...inputData,
          title: data.letter.title,

          content_01_id: arr_01.length > 0 ? arr_01[0].contents.id : null,
          image_01_id:
            arr_01.length > 0
              ? arr_01[0].contents.images
                ? arr_01[0].contents.images.id
                : null
              : null,
          image_01_id_url:
            arr_01.length > 0
              ? arr_01[0].contents.images
                ? arr_01[0].contents.images.image_url
                : null
              : null,
          title_01: arr_01.length > 0 ? arr_01[0].contents.title : "",
          content_01: arr_01.length > 0 ? arr_01[0].contents.content : "",

          content_02_id: arr_02.length > 0 ? arr_02[0].contents.id : null,
          image_02_id:
            arr_02.length > 0
              ? arr_02[0].contents.images
                ? arr_02[0].contents.images.id
                : null
              : null,
          image_02_id_url:
            arr_02.length > 0
              ? arr_02[0].contents.images
                ? arr_02[0].contents.images.image_url
                : null
              : null,
          title_02: arr_02.length > 0 ? arr_02[0].contents.title : "",
          content_02: arr_02.length > 0 ? arr_02[0].contents.content : "",

          content_03_id: arr_03.length > 0 ? arr_03[0].contents.id : null,
          image_03_id:
            arr_03.length > 0
              ? arr_03[0].contents.images
                ? arr_03[0].contents.images.id
                : null
              : null,
          image_03_id_url:
            arr_03.length > 0
              ? arr_03[0].contents.images
                ? arr_03[0].contents.images.image_url
                : null
              : null,
          title_03: arr_03.length > 0 ? arr_03[0].contents.title : "",
          content_03: arr_03.length > 0 ? arr_03[0].contents.content : "",

          content_04_id: arr_04.length > 0 ? arr_04[0].contents.id : null,
          image_04_id:
            arr_04.length > 0
              ? arr_04[0].contents.images
                ? arr_04[0].contents.images.id
                : null
              : null,
          image_04_id_url:
            arr_04.length > 0
              ? arr_04[0].contents.images
                ? arr_04[0].contents.images.image_url
                : null
              : null,
          title_04: arr_04.length > 0 ? arr_04[0].contents.title : "",
          content_04: arr_04.length > 0 ? arr_04[0].contents.content : "",

          content_05_id: arr_05.length > 0 ? arr_05[0].contents.id : null,
          image_05_id:
            arr_05.length > 0
              ? arr_05[0].contents.images
                ? arr_05[0].contents.images.id
                : null
              : null,
          image_05_id_url:
            arr_05.length > 0
              ? arr_05[0].contents.images
                ? arr_05[0].contents.images.image_url
                : null
              : null,
          title_05: arr_05.length > 0 ? arr_05[0].contents.title : "",
          content_05: arr_05.length > 0 ? arr_05[0].contents.content : "",

          letter: data,
        });
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  // 카테고리 조회
  const getCategoryList = async () => {
    setLoading(true);
    try {
      const {
        data: { data: first },
      } = await lmsChildApi.getNewsSupportList({
        type: "first",
      });
      const {
        data: { data: second },
      } = await lmsChildApi.getNewsSupportList({
        type: "second",
      });
      setFilterList({
        ...filterList,
        first_category: first.first_category.map((i) => {
          return {
            value: i.id,
            label: i.category_name,
          };
        }),
        second_category: second.second_category.map((i) => {
          return {
            value: i.id,
            label: i.category_name,
          };
        }),
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
      if (url.id) {
        getTempData(url.id);
      }
      setTimeout(() => {
        setState({
          ...state,
          data_loading: false,
        });
        setLoading(false);
      }, 500);
    }
  };
  // 이미지풀 조회
  const getImageList = async () => {
    try {
      const {
        data: { data },
      } = await lmsChildApi.getNewsSupportList({
        type: "image",
        search: state.search_keyword,
        page: currentPage - 1,
      });
      setFilterList({
        ...filterList,
        image_list: data.image,
        image_total: data.total,
      });
      console.log(data);
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 이미지 직접 업로드
  const imageUpload = async (type, value, id) => {
    if (type === "save") {
      const form = setFormData({
        image: value,
      });
      try {
        const { data } = await lmsChildApi.setImage(form);
        setInputData({
          ...inputData,
          [state.modal.id]: data.data.id,
          [`${state.modal.id}_url`]: data.data.image_url,
        });
        setState({
          ...state,
          [`image_upload_${state.modal.id}`]: true,
          modal: { open: false, type: "", id: "" },
        });
      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {
      if (state[`image_upload_${state.modal.id}`]) {
        try {
          const { data } = await lmsChildApi.deleteImage(inputData.image_id);
          setState({
            ...state,
            image_upload: false,
            modal: {
              open: false,
              type: "picture_select",
              id: `image_0${id}_id`,
            },
          });
        } catch (error) {
          console.log(error);
          // alert(res.data.message);
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
        } finally {
          setLoading(false);
        }
      }
    }
  };
  // 레터 작성시 : 나의 뉴스 조회
  const getmyNewsList = async () => {
    try {
      const {
        data: { data },
      } = await lmsChildApi.getNewsList({
        year: state.news_year,
        month: state.news_month,
        page: currentPage - 1,
      });
      setFilterList({
        ...filterList,
        news_list: data.news,
        news_total: data.total,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 레터 작성시 : 뉴스 불러오기
  const setNews = async (data) => {
    setInputData({
      ...inputData,
      [`image_0${state.modal.id}_id`]: data.contents[0].images.id,
      [`image_0${state.modal.id}_id_url`]: data.contents[0].images.image_url,
      [`title_0${state.modal.id}`]: data.title,
      [`content_0${state.modal.id}`]: data.contents[0].content,
    });
    setState({
      ...state,
      modal: { open: false, type: "", id: "" },
    });
  };

  // 뉴스 저장
  const saveNews = async (type) => {
    if (type === "save") {
      if (!inputData.first_category) {
        setStateValue("alert", {
          title: "첫번째 카테고리를 선택해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!inputData.second_category) {
        setStateValue("alert", {
          title: "두번째 카테고리를 선택해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.title === "") {
        setStateValue("alert", {
          title: "제목을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!inputData.image_id) {
        setStateValue("alert", {
          title: "이미지를 선택해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.content === "") {
        setStateValue("alert", {
          title: "내용을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
    } else {
      if (inputData.title === "") {
        setStateValue("alert", {
          title: "임시 저장시 제목입력은 필수입니다. 제목을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
    }
    const json = {
      first_category_id: inputData.first_category,
      second_category_id: inputData.second_category,
      image_id: inputData.image_id,
      title: inputData.title,
      content: inputData.content,
    };
    try {
      const {
        data: { data },
      } = await lmsChildApi.setNews(
        { type: type, id: url.id ? url.id : "null" },
        json
      );
      setStateValue("alert", {
        title: "저장이 완료됐어요!",
        sub: null,
        isOk: () => (window.location.href = "/lms/student/dashboard/news"),
        isCancel: null,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 뉴스레터 저장
  const saveNewsLetter = async (type) => {
    if (type === "save") {
      if (inputData.title === "") {
        setStateValue("alert", {
          title: "제목입력은 필수입니다. 제목을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.title_01 === "") {
        setStateValue("alert", {
          title: "첫번째 문단의 제목을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.content_01 === "") {
        setStateValue("alert", {
          title: "첫번째 문단의 내용을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (!inputData.image_01_id) {
        setStateValue("alert", {
          title: "첫번째 문단의 이미지를 선택해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.title_02 !== "") {
        if (inputData.content_02 === "") {
          setStateValue("alert", {
            title:
              "두번째 문단의 내용을 입력해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
            width: 400,
          });
          return;
        }
        if (!inputData.image_02_id) {
          setStateValue("alert", {
            title:
              "두번째 문단의 이미지를 선택해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 두번째 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
      }
      if (inputData.title_03 !== "") {
        if (inputData.content_03 === "") {
          setStateValue("alert", {
            title:
              "세번째 문단의 내용을 입력해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
        if (!inputData.image_03_id) {
          setStateValue("alert", {
            title:
              "세번째 문단의 이미지를 선택해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
      }
      if (inputData.title_04 !== "") {
        if (inputData.content_04 === "") {
          setStateValue("alert", {
            title:
              "네번째 문단의 내용을 입력해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
        if (!inputData.image_04_id) {
          setStateValue("alert", {
            title:
              "네번째 문단의 이미지를 선택해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
      }
      if (inputData.title_04 !== "") {
        if (inputData.content_04 === "") {
          setStateValue("alert", {
            title:
              "다섯번째 문단의 내용을 입력해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
        if (!inputData.image_04_id) {
          setStateValue("alert", {
            title:
              "다섯번째 문단의 이미지를 선택해주세요. 이 문단을 작성하시고 싶지 않으시면 이 문단의 제목과 내용, 이미지를 삭제해주세요.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
      }
    } else {


      if (inputData.title === "") {

        

        setStateValue("alert", {
          title: "임시 저장시 제목입력은 필수입니다. 제목을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }


    }
    const contentArr = [
      {
        content_id: inputData.content_01_id,
        title: inputData.title_01,
        content: inputData.content_01,
        image_id: inputData.image_01_id,
        image_size: "large",
        image_location: "full",
        layout_number: 1,
      },
    ];
    if (inputData.image_02_id) {
      contentArr.push({
        content_id: inputData.content_02_id,
        title: inputData.title_02,
        content: inputData.content_02,
        image_id: inputData.image_02_id,
        image_size: "large",
        image_location: "full",
        layout_number: 2,
      });
    }
    if (inputData.image_03_id) {
      contentArr.push({
        content_id: inputData.content_03_id,
        title: inputData.title_03,
        content: inputData.content_03,
        image_id: inputData.image_03_id,
        image_size: "large",
        image_location: "full",
        layout_number: 3,
      });
    }
    if (inputData.image_04_id) {
      contentArr.push({
        content_id: inputData.content_04_id,
        title: inputData.title_04,
        content: inputData.content_04,
        image_id: inputData.image_04_id,
        image_size: "small",
        image_location: "left",
        layout_number: 4,
      });
    }
    if (inputData.image_05_id) {
      contentArr.push({
        content_id: inputData.content_05_id,
        title: inputData.title_05,
        content: inputData.content_05,
        image_id: inputData.image_05_id,
        image_size: "small",
        image_location: "right",
        layout_number: 5,
      });
    }

    const json = {
      template_id: 1,
      title: inputData.title,
      contents: contentArr,
    };
    try {
      const { data } = await lmsChildApi.setLetter(
        { type: type, id: url.id ? url.id : "null" },
        json
      );
      setStateValue("alert", {
        title: "저장이 완료됐어요!",
        sub: null,
        isOk: () =>
          (window.location.href = "/lms/student/dashboard/newsletter"),
        isCancel: null,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 뉴스 url
  const getNewsUrl = async (type) => {
    try {
      const {
        data: { data },
      } = await lmsCommonApi.getNewsUrl({ type: type, id: url.id });
      console.log(data);
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/share/${type}/${data.public_number}`
        );
        setModalData(true, "copy");
      } catch (e) {
        setStateValue("alert", {
          title: "복사에 실패하였습니다",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      }
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  // 뉴스 다운로드
  const getNewDownload = useCallback(
    (fileName, type) => {
      if (ref.current === null) {
        return;
      }
      toJpeg(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${fileName}.jpeg`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [ref]
  );

  // keyword로 검색 결과 셋팅
  const searchList = () => {
    console.log(state.search_keyword);
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      getImageList();
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
    console.log(key, value);
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    if (type === "picture_select" && open) {
      if (state.image_upload) {
        imageUpload("delete");
        setInputData({
          ...inputData,
          image_id: null,
          image_url: null,
        });
        setState({
          ...state,
          modal: { open: open, type: type, id: id },
          image_upload: false,
        });
      } else {
        setState({ ...state, modal: { open: open, type: type, id: id } });
      }
    } else if (type === "copy" && open) {
      // getNewsUrl("news");
      setState({ ...state, modal: { open: open, type: type, id: id } });
    } else {
      setState({ ...state, modal: { open: open, type: type, id: id } });
      setCurrentPage(1);
    }
    // type : info /cancel
  };
  // state input 셋팅
  const setInputStateValue = (key, value, url) => {
    console.log(key, value, url, state.modal);
    if (key === "image") {
      setInputData({
        ...inputData,
        [state.modal.id]: value,
        [`${state.modal.id}_url`]: url,
      });
      setState({
        ...state,
        modal: { open: false, type: "", id: "" },
        search_keyword: "",
        image_upload: false,
      });
    } else {
      if (key === "title") {
        if (value.length > 25) {
          setStateValue("alert", {
            title: "뉴스의 제목은 최대 25글자입니다.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
      } else if (key === "content") {
        if (value.length > 450) {
          setStateValue("alert", {
            title: "뉴스의 내용은 최대 450글자입니다.",
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          return;
        }
      }
      setInputData({ ...inputData, [key]: value });
    }
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    console.log(value);
    setCurrentPage(value);
  };
  return {
    state,
    data: { dataList, currentPage, filterList, inputData },
    ref: { ref },
    func: {
      setStateValue,
      setModalData,
      paging,
      searchList,
      setInputStateValue,
      saveNews,
      imageUpload,
      getNewsUrl,
      getNewDownload,
      setNews,
      saveNewsLetter,
      setInputData
    },
  };
};

export default BuilderLogic;
