import styled from "styled-components";

export const LmsSt = styled.div`
  position: relative;
  min-height: calc(100vh - 38px);
  background-color: var(--grey1);

  /* min-width: calc(100% - 320px); */
  width: 100%;
  &.on {
    background-color: var(--white);
  }
  input {
    height: 34px;
    width: 100%;
    border: 1px solid var(--grey4);
    padding-left: 12px;
    border-radius: 4px;
    font-size: 14px;
    ::placeholder {
      color: var(--grey3);
    }
  }
  // 발간된 뉴스 빌더 배경
  .news_builder_bg {
    background-color: var(--white);
  }
  // 발간된 뉴스레터 빌더 배경
  .newsletter_builder_bg {
    background-color: var(--white);
  }
`;

export const LmsNav = styled.div`
  width: 320px;
  padding: 32px 24px;
  padding-top: 82px;
  border-right: 1px solid var(--grey3);
  min-height: 964px;
  display: inline-block;
  position: relative;
  background-color: var(--white);
  > div {
    margin-bottom: 40px;
    h3 {
      font-size: 20px;
      font-weight: 700;
      color: var(--text);
      line-height: 140%;
      -webkit-letter-spacing: -0.04em;
      -moz-letter-spacing: -0.04em;
      -ms-letter-spacing: -0.04em;
      letter-spacing: -0.04em;
      width: 70%;
      display: inline-block;
    }
    span {
      display: inline-block;
      float: right;
      width: 68px;
      height: 32px;
      margin-top: 15px;
      background: #f2f4f6;
      border-radius: 100px;
      padding: 10px 10px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.04em;
      color: #646f7c;
      cursor: pointer;
      vertical-align: middle;
      img {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  .menu {
    display: block;
    position: relative;
    > li {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #28323c;
      vertical-align: middle;
      padding: 12px 10px;
      cursor: pointer;
      border-radius: 6px;
      margin-bottom: 10px;
      height: 52px;
      > &:hover {
        background-color: var(--grey2);
        transition-duration: 0.4s;
      }
      &.on {
        background-color: var(--grey2);
      }
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        vertical-align: sub;
        display: inline-block;
        margin-bottom: -3px;
      }

      .sub {
        margin-top: 10px;
        li {
          height: 48px;
          padding: 14px 10px;
          margin-bottom: 0;
          font-weight: 400;
          font-size: 14px;
          &.on {
            font-weight: 700;
            color: #28323c;
            p {
              font-weight: 700;
              color: #28323c;
            }
          }
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #646f7c;
            font-size: 14px;
          }
        }
      }
    }
  }
  .yearly_subscription {
    position: absolute;
    bottom: 0px;
    background: var(--grey1);
    border-radius: 8px;
    width: 272px;
    padding: 20px;
    img {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: top;
      margin-right: 4px;
    }
    h5 {
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: var(--text);
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: var(--text);
    }
  }
`;
export const LmsDashboard = styled.div`
  min-height: calc(100vh - 38px);
  width: calc(100% - 320px);
  display: inline-block;
  background-color: var(--grey1);
  vertical-align: top;
  padding: 32px 40px;
  padding-top: 92px;
  position: relative;
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 140%;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.04em;
    margin-bottom: 20px;
    display: block;
  }

  .selectbox {
    height: 40px;
    margin-bottom: 16px;
  }
  // 뉴스 놀이터 참여 현황 조회
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: var(--white);
    border-radius: 6px;
    padding: 20px;
    display: block;
    font-size: 14px;
    thead {
      background-color: var(--grey2);
      height: 48px;
      width: 100%;
      display: block;
      line-height: 48px;
      color: var(--grey6);
      border-radius: 6px;
      tr {
        height: 48px;
        line-height: 48px;
        width: 100%;
        display: block;
        td {
          display: inline-block;
          padding: 14px 20px;
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2) {
            width: 60%;
          }
          &:nth-child(3) {
            width: 10%;
          }
        }
      }
    }
    tbody {
      width: 100%;
      display: block;
      color: var(--text);
      tr {
        height: 48px;
        line-height: 48px;
        width: 100%;
        display: block;
        cursor: pointer;
        td {
          display: inline-block;
          padding: 14px 20px;
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2) {
            width: 60%;
          }
          &:nth-child(3) {
            width: 10%;
          }
          .tag {
            display: block;
            border-radius: 4px;
            width: 68px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            &.finish {
              background: #f0ebff;
              color: var(--main);
            }
            &.not_yet {
              background: var(--grey2);
              color: var(--grey5);
            }
          }
        }
        &.lms_empty {
          height: 100%;
          td {
            width: 100%;
            &:nth-child(1) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  // 나의 뉴스 | 뉴스 빌더 조회
  .newsbuilder {
    width: 100%;
    background: var(--white);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    li {
      padding: 10px 12px;
      position: relative;
      margin-bottom: 12px;
      > div {
        display: inline-block;
        &:nth-child(1) {
          width: 88px;
          height: 88px;
          margin-right: 20px;
          border-radius: 4px;
          background-color: var(--grey2);
          position: relative;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 88px;
            height: 88px;
            display: block;
            object-fit: cover;
            &.newsbuilder_picture_icon {
              width: 20px;
              height: 20px;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -10px;
              margin-left: -10px;
            }
          }
        }
        &:nth-child(2) {
          vertical-align: top;
          .tagbox {
            margin-bottom: 8px;
            span {
              background-color: var(--grey2);
              padding: 4px 8px;
              border-radius: 4px;
              font-weight: 500;
              font-size: 12px;
              line-height: 140%;
              letter-spacing: -0.04em;
              color: var(--grey6);
              margin-right: 6px;
              display: inline-block;
            }
          }
          h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: var(--text);
            margin-bottom: 10px;
            cursor: pointer;
            span {
              color: var(--main);
            }
          }
          h6 {
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: var(--grey5);
            font-weight: 400;
          }
        }
        &:nth-child(3) {
          font-size: right;
          vertical-align: top;
          margin-top: 20px;
          width: 48px;
          height: 40px;
          border: 1px solid #c5c8ce;
          border-radius: 100px;
          cursor: pointer;
          position: absolute;
          right: 0;
          display: none;
          &:hover {
            background-color: var(--grey1);
            transition-duration: 0, 4s;
          }
          img {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 auto;
            margin-top: 9px;
            object-fit: cover;
          }
        }
      }
      &:hover {
        > div {
          &:nth-child(3) {
            display: inline-block;
            transition-duration: 0.4s;
          }
        }
      }
    }
  }

  // 나의 뉴스레터 빌더 조회
  .newsletterbuilder {
    width: 100%;
    display: grid;
    flex-direction: column;
    gap: 34px 20px;
    grid-template-columns: repeat(auto-fill, calc(25% - 15px));
    background-color: var(--white);
    padding: 36px 32px;
    margin-top: 20px;
    li {
      border-radius: 6px;
      margin-right: 20px;
      position: relative;
      .newsletter_picture {
        background-color: var(--grey2);
        height: 173px;
        display: block;
        border: 1px solid #e9ebee;
        border-radius: 6px;
        position: relative;
        margin-bottom: 10px;
        position: relative;
        img {
          position: absolute;
          width: 100%;
          height: 173px;
          background-color: var(--grey1);
          object-fit: cover;
          top: 0;
          left: 0;
          border-radius: 6px;
          overflow: hidden;
        }
        &:hover {
          cursor: pointer;
          .trash_box {
            display: block;
          }
        }
        > img {
          width: 100%;
          height: 173px;
          background-color: var(--grey1);
          object-fit: cover;
        }
        .month {
          position: absolute;
          top: 7%;
          right: 7%;
          font-size: 10px;
          padding: 5px 10px;
          color: var(--main);
          background: #f0ebff;
          z-index: 9;
          height: auto;
          border-radius: 6px;
        }
        .title {
          position: absolute;
          top: 69%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          font-size: 12px;
          color: var(--text);
          z-index: 9;
          height: auto;
          width: 90%;
          line-height: 110%;
          text-align: center;
        }
        .name {
          position: absolute;
          top: 77.5%;
          right: 10%;
          z-index: 9;
          height: 15px;
          width: 85px;
          text-align: right;
          font-size: 10px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.04em;
        color: var(--text);
        height: 48px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
        > span {
          color: var(--main);
        }
      }
      > span {
        background-color: var(--grey2);
        padding: 6px 10px;
        gap: 10px;
        height: 29px;
        background: #f2f4f6;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #646f7c;
      }
      .trash_box {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        display: none;
        z-index: 20;
        img {
          display: block;
          margin: 0 auto;
          width: 20px;
          height: 20px;
          margin-top: 5px;
          background-color: transparent;
          margin-left: 6px;
        }
      }
    }
  }

  // 좋아한 뉴스 조회
  .likednews {
    width: 100%;
    display: grid;
    flex-direction: column;
    gap: 36px 20px;
    grid-template-columns: repeat(auto-fill, calc(33% - 11px));
    background-color: var(--white);
    padding: 36px 32px;
    object-fit: cover;
    li {
      border-radius: 6px;
      margin-right: 20px;
      position: relative;
      .newsletter_picture {
        background-color: var(--grey2);
        height: 216px;
        display: block;
        border: 1px solid #e9ebee;
        border-radius: 6px;
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;

        img {
          width: 100%;
          height: 216px;
          background-color: var(--grey1);
          object-fit: cover;
          border-radius: 6px;
        }
      }

      .tagbox {
        span {
          padding: 4px 8px;
          background: #f2f4f6;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #646f7c;
          margin-right: 6px;
          margin-top: 10px;
          margin-bottom: 8px;
          display: inline-block;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.04em;
        color: #28323c;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 48px;
        font-weight: 600;
      }
      h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #a2a7b1;
      }
    }
  }

  // 나의 댓글 조회
  .mycomment {
    width: 100%;
    background: var(--white);
    border-radius: 10px;
    padding: 24px 32px;
    > li {
      margin-bottom: 36px;
      .mycomment_picture {
        margin-bottom: 6px;
        padding: 10px;
        img {
          border-radius: 4px;
          width: 50px;
          height: 50px;
          display: inline-block;
          vertical-align: middle;
          object-fit: cover;
          overflow: hidden;
          background: var(--grey1);
        }
        > div {
          display: inline-block;
          vertical-align: middle;
          margin-left: 8px;
          h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: var(--text);
            margin-bottom: 0;
          }
          h6 {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: var(--grey5);
          }
        }
      }
      > ul {
        > li {
          padding: 14px 16px;
          background: var(--grey2);
          border-radius: 4px;
          margin-bottom: 8px;
          box-sizing: border-box;
          img {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: top;
            margin-right: 6px;
          }
          p {
            display: inline-block;
            vertical-align: top;
            font-weight: 400;
            font-size: 15px;
            line-height: 180%;
            letter-spacing: -0.04em;
            color: var(--grey7);
            width: 95%;
            margin-bottom: 6px;
          }
          .tag {
            display: block;
            width: 100%;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            margin-left: 22px;
            &.approve_tag {
              color: var(--main);
            }
            &.time_tag {
              color: var(--grey5);
            }
            &.red_tag {
              color: var(--red);
            }
          }
        }
      }
    }
  }



  // 뉴스빌더 탬플릿 심볼
  .newsbuilder_symbol_img1 {
    position: absolute;
    width: 428px;
    height: 329px;
    top: 65%;
    left: -7%;
  }
  .newsbuilder_symbol_img2 {
    position: absolute;
    width: 428px;
    height: 329px;
    top: 15%;
    right: 1%;
  }

  .pos-r{
    position:relative;
  }

  #tooltip1[style*='display: block'] + .selectbox > div > div,
  #tooltip2[style*='display: block'] + .selectbox > div > div,
  #tooltip3[style*='display: block'] + input,

  #tooltip4[style*='display: block'] + .textarea_box,
  #tooltip4[style*='display: block'] + .quill-box,

  #tooltip5[style*='display: block'] + .save_button{
    border: 2px solid #6c38ff; 
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.24);
  }

    @keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
  }

  .guide_tooltip{
    display:block;
    animation: fadeIn 1s;
    position: absolute;
    background: #6c38ff;
    color: white;
    z-index: 10;
    top: -20px;
    border-radius: 10px;
    padding: 12px 15px;
    font-size: 15px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    left: 250px;
    line-height:1.5;

    ul li{
      line-height:1.5;
    }

    &#tooltip1{
      top : -52px;
      left: 170px;
    }

    &#tooltip2{
     top: -32px;
     left: 185px;
    }

    &#tooltip3{
     top: -100px;
     left: 250px;
    }

    &#tooltip4{
     top: -187px;
     left: -192px;

     &::before{
        left: 325px;
     }
    }

    &#tooltip5{
      top: -83px;
      left: 216px;
    }


    &:before{
    content: '';
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid #6c38ff;
    position: absolute;
    bottom: -13px;
    left: 40px;
    
    }

    /*
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid white;
      position: absolute;
      top: 38px;
      left: 30px;
    }
    */

  }


  .ql-editor{

  }


  // 뉴스빌더 탬플릿 타입
  .newsbuilder_type {
    width: 728px;
    padding: 28px;
    background-color: var(--white);
    border: 1px solid #f2f4f6;
    box-shadow: 0px 6px 20px rgba(170, 176, 187, 0.1);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 24px;
    position: relative;
    .newsbuilder_how {
      cursor: pointer;
      border-radius: 6px;
      padding: 10px;
      width: fit-content;
      position: absolute;
      right: 30px;
      background: rgba(123, 97, 255, 0.1);
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-left: 4px;
        color: var(--main);
      }
    }
    > div {
      margin-bottom: 24px;
      &:last-child {
        margin: 0;
      }
      h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #374553;
        margin-bottom: 6px;
      }
      input[type="text"] {
        width: 100%;
        border: 1px solid #e9ebee;
        border-radius: 8px;
        height: 48px;
        &::placeholder {
          color: var(--grey5);
        }
        &:focus {
          border: 1px solid var(--main);
        }
      }
      // input file label(x) / 사진 선택
      .imgbox {
        width: 672px;
        height: 279px;
        background-color: var(--grey1);
        display: block;
        cursor: pointer;
        > div {
          padding-top: 117px;
          .newsbuilder_picture_icon {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 auto;
            margin-bottom: 6px;
          }
          p {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #646f7c;
          }
        }
      }

      .imgbox_upload {
        width: 672px;
        height: 279px;
        cursor: pointer;
        position: relative;
        img {
          width: 672px;
          height: 279px;
          display: block;
          object-fit: cover;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          );
          display: none;
          width: 100%;
          height: 100%;
          > div {
            padding-top: 117px;
            img {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-bottom: 6px;
            }
            p {
              text-align: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.04em;
              color: var(--white);
            }
          }
        }
        &:hover {
          span {
            display: block;
          }
        }
      }
      > .textarea_box {
        border: 1px solid #e9ebee;
        width: 100%;
        border-radius: 8px;
        position: relative;
        textarea {
          width: 100%;
          display: block;
          font-size: 14px;
          border-radius: 8px;
          padding: 14px 16px;
          &:focus {
            outline: 1px solid var(--main);
          }
          &.red_textarea {
            outline: 1px solid var(--red);
          }
          &::placeholder {
            color: var(--grey5);
          }
        }
      }
      .max_length {
        display: block;
        font-size: 14px;
        line-height: 170%;
        letter-spacing: -0.04em;
        color: #a2a7b1;
        text-align: right;
      }
    }
    .alert_text {
      width: 524px;
      height: 50px;
      padding: 14px;
      background-color: var(--grey1);
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #646f7c;
      border-radius: 8px;
      margin-top: 24px;
      display: inline-block;
      vertical-align: middle;

      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        margin-bottom: 3px;
      }
    }
    button.custom {
      margin-top: 24px;
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      background-color: var(--main);
      color: var(--white);
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      width: 136px;
      height: 42px;
    }

    .recommend_write {
      background-color: var(--grey1);
      padding: 14px;
      border-radius: 8px;
      .alert_text {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #646f7c;
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;
        padding: 0;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
          margin-bottom: 3px;
        }
      }
      div {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #646f7c;
        letter-spacing: -0.04em;
        margin-bottom: 12px;
        border-radius: 8px;
        span {
          width: fit-content;
          background: #e9ebee;
          border-radius: 4px;
          padding: 4px 8px;
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          margin-right: 8px;
        }
      }
    }
    .save_button {
      background-color: transparent;
      border: 1px solid var(--main);
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #6c38ff;
      display: block;
      height: 44px;
      border-radius: 6px;
      margin-left: 0;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
  }
`;

// 뉴스빌더 바로가기 상단 컴포넌트
export const NewsBuilderGo = styled.div`
  width: 100%;
  height: 133px;
  background: var(--main);
  border-radius: 10px;
  position: relative;
  padding: 28px 32px;
  img {
    width: 230px;
    height: 130px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  h4 {
    color: var(--white);
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.04em;
    margin-bottom: 10px;
  }
  button {
    background-color: var(--white);
    color: var(--main);
    border-radius: 6px;
    padding: 11px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.04em;
  }
`;

// 발간된 뉴스빌더
export const NewsBuilderSave = styled.div`
  width: 744px;
  margin: 0 auto;
  padding-top: 100px;
  @media screen and (max-width: 1199px) and (min-width: 810px) {
    width: 730px;
  }
  @media screen and (max-width: 809px) {
    width: 90%;
  }
  #news {
    // 다운로드 부분 때문에 필요
    background-color: var(--white);
    padding: 50px;
    // 발간된 뉴스 사진
    .news_builder_img {
      margin-bottom: 30px;
      display: block;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      color: #374553;
    }
  }
  .tag_list {
    li {
      display: inline-block;
      margin-bottom: 20px;
      color: var(--main);
      font-size: 18px;
      font-weight: 500;
      width: fit-content;
      &:nth-child(1) {
        border-right: 1px solid var(--grey4);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }
  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #2e2e2e;
    margin-bottom: 20px;
    @media screen and (max-width: 809px) {
      font-size: 26px;
    }
  }
  .info {
    margin-bottom: 50px;
    li {
      display: inline-block;
      margin-right: 20px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #646f7c;
        margin-bottom: 0;
        @media screen and (max-width: 809px) {
          font-size: 16px;
        }
      }
    }
  }

  .tab {
    width: 744px;
    margin: 0 auto;
    text-align: right;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 720px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      text-align: left;
    }
    span {
      display: inline-block;
      background: #f2f4f6;
      border-radius: 6px;
      padding: 11px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #a2a7b1;
      margin: 50px 0;
      margin-right: 8px;
      cursor: pointer;
      &.on {
        background-color: var(--main);
        color: var(--white);
      }
    }
  }
  > img {
    width: 744px;
    display: block;
    object-fit: cover;
    margin-bottom: 30px;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 730px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      height: 209px;
      margin-bottom: 30px;
    }
  }
  > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 190%;
    letter-spacing: -0.04em;
    color: #374553;
    margin-bottom: 47px;
    @media screen and (max-width: 809px) {
      font-size: 16px;
    }
  }
  .side_imgbox {
    width: 100%;
    .side_template {
      > div {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 30px;
        @media screen and (max-width: 809px) {
          width: 100%;
        }
        &:nth-child(1) {
          padding-right: 30px;
          @media screen and (max-width: 809px) {
            padding-right: 0;
          }
        }
        > p {
          font-weight: 400;
          font-size: 18px;
          line-height: 190%;
          letter-spacing: -0.04em;
          color: #374553;
          display: inline-block;
          vertical-align: top;
          @media screen and (max-width: 809px) {
            font-size: 16px;
          }
        }
      }
    }

    > p {
      width: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      color: #374553;
      margin-bottom: 30px;
      @media screen and (max-width: 809px) {
        font-size: 16px;
      }
    }
  }

  .btns {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 90px;
    button {
      padding: 14px 16px;
      border: 1px solid var(--main);
      border-radius: 6px;
      box-sizing: border-box;
      height: 52px;
      &:nth-child(1) {
        margin-right: 20px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        color: var(--main);
        margin-bottom: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }
  .newsbtns {
    margin-top: 30px;
    button {
      background: #f7f8f9;
      border-radius: 6px;
      padding: 10px 16px;
      cursor: pointer;
      &.liked {
        background: #f0ebff;
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      > img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      > p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: var(--grey5);
        width: fit-content;
        &.liked {
          color: var(--main);
        }
      }
    }
  }
  .center {
    width: 100%;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 475px;
      object-fit: cover;
      display: block;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      margin-top: 30px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      img {
        height: 209px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .left {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    img {
      height: 510px;
      object-fit: cover;
      width: 50%;
      vertical-align: top;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      width: 50%;
      vertical-align: top;
      padding-left: 30px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      flex-direction: column;
      img {
        width: 100%;
        display: block;
        height: 468px;
      }
      p {
        font-size: 16px;
        width: 100%;
        display: block;
        margin-top: 30px;
        padding-left: 0;
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      width: 50%;
      vertical-align: top;
      padding-right: 30px;
    }
    img {
      height: 510px;
      object-fit: cover;
      width: 50%;
      vertical-align: top;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      flex-direction: column-reverse;
      p {
        font-size: 16px;
        width: 100%;
        margin-top: 30px;
        padding-right: 0;
        order: 1;
      }
      img {
        width: 100%;
        height: 468px;
        order: 2;
      }
    }
  }
`;
// 발간된 뉴스레터 빌더
export const NewsLetterBuilderSave = styled.div`
  width: 595px;
  margin: 0 auto;
  margin-top: 100px;
  background-color: var(--white);
  #news {
    // 다운로드 부분 때문에 필요
    background-color: var(--white);
  }
  .btns {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 90px;
    margin-top: 40px;
    button {
      padding: 14px 16px;
      border: 1px solid var(--main);
      border-radius: 6px;
      box-sizing: border-box;
      height: 52px;
      &:nth-child(1) {
        margin-right: 20px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        color: var(--main);
        margin-bottom: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }
`;

// 뉴스빌더 페이지 상단 컴포넌트
export const NewsBuilderPage = styled.div`
  width: ${({ type }) => (type === "news" ? 728 : 595) + "px"};
  // width: 728px;
  height: 220px;
  background: #f0ebff;
  border-radius: 10px;
  position: relative;
  padding: 32px;
  margin: 0 auto;
  margin-bottom: 24px;
  .title {
    width: 595px;
  }
  > div {
    position: absolute;
    bottom: 32px;
    h1 {
      font-weight: 700;
      font-size: 26px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #374553;
      margin-top: 60px;
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: -0.04em;
      color: #374553;
    }
    button {
      margin-top: 10px;
      background: #ffffff;
      border-radius: 6px;
      padding: 10px 12px;
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 8px;
      }
      p {
        color: var(--main);
        font-weight: 500;
        display: inline-block;
      }
    }
  }
  img {
    &:nth-child(2) {
      width: 78px;
      height: 80px;
      position: absolute;
      width: ${({ type }) => (type === "news" ? 78 : 50) + "px"};
      bottom: ${({ type }) => (type === "news" ? 100 : 70) + "px"};
      right: ${({ type }) => (type === "news" ? 150 : 80) + "px"};
    }
    &:nth-child(3) {
      // width: 144px;
      //height: 120px;
      position: absolute;
      bottom: 0;
      right: 32px;
      width: ${({ type }) => (type === "news" ? 144 : 106) + "px"};
      height: ${({ type }) => (type === "news" ? 120 : 88) + "px"};
    }
  }
`;

// 뉴스레터 빌더 탬플릿
export const NewsLetterTemplate = styled.div`
  border: 10px solid var(--main);
  width: 595px;
  margin: 0 auto;
  background-color: var(--white);
  position: relative;
  .newsletter_builder_symbol {
    position: absolute;
    left: 10px;
    bottom: 87px;
    width: 137px;
    height: 132.99px;
  }
  .newsletter_builder_wrap {
    padding: 44px 54px;
    .header {
      width: 100%;
      border-bottom: 1px solid var(--grey3);
      padding-bottom: 28px;
      > img {
        width: 104px;
        height: 30px;
        vertical-align: middle;
        display: inline-block;
        margin-top: 8px;
      }
      > div {
        float: right;
        height: 46px;

        > img {
          width: 73px;
          height: 46px;
          vertical-align: middle;
          display: inline-block;
        }
        span {
          padding: 6px 10px;
          height: 36px;
          background: #e2d7ff;
          border-radius: 6px;
          background-color: #e2d7ff;
          color: var(--main);
          font-weight: 700;
          vertical-align: middle;
          display: inline-block;
          line-height: 24px;
        }
      }
    }
    .title {
      margin-bottom: 40px;
      > img {
        width: 111px;
        height: 116px;
        display: block;
        margin: 40px auto;
      }
      input[type="text"] {
        font-weight: 700;
        font-size: 26px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #28323c;
        border: none;
        padding-left: 0;
        margin-bottom: 10px;
        text-align: center;
        &::placeholder {
          color: var(--grey5);
        }
      }
      h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #28323c;
        text-align: center;
        margin-bottom: 10px;
      }

      div {
        text-align: center;
        margin-bottom: 40px;
        img {
          display: inline-block;
          vertical-align: middle;
          width: 60px;
          height: 22px;
          margin-right: 14px;
        }
        p {
          display: inline-block;
          vertical-align: middle;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          text-align: center;
          letter-spacing: -0.04em;
          color: #646f7c;
        }
      }
    }
    //  뉴스레터 빌터 컴포넌트1
    .newsletter_component {
      margin-bottom: 90px;
      .imgbox {
        width: 467px;
        height: 248px;
        background-color: var(--grey1);
        display: block;
        cursor: pointer;
        position: relative;
        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          .newsbuilder_picture_icon {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 auto;
            margin-bottom: 6px;
          }
          p {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #646f7c;
          }
        }
      }

      //  뉴스레터 빌터 컴포넌트1 - 이미지 업로드
      .imgbox_upload {
        width: 467px;
        height: 248px;
        cursor: pointer;
        position: relative;
        img {
          width: 467px;
          height: 248px;
          display: block;
          object-fit: cover;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          );
          display: none;
          width: 100%;
          height: 100%;
          > div {
            padding-top: 117px;
            img {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-bottom: 6px;
            }
            p {
              text-align: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.04em;
              color: var(--white);
            }
          }
        }
        &:hover {
          span {
            display: block;
          }
        }
      }
      .news_bring {
        margin-top: 30px;
        input[type="text"] {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #374553;
          border: none;
          padding-left: 0;
          margin-bottom: 20px;
          &::placeholder {
            color: var(--grey5);
          }
        }
        h2 {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #374553;
          border: none;
          padding-left: 0;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 170%;
          letter-spacing: -0.04em;
          color: #374553;
          margin-bottom: 10px;
        }
        button {
          padding: 11px 16px;
          height: 42px;
          background: #f2f4f6;
          border-radius: 6px;
          color: var(--grey6);
        }
        > div {
          position: relative;
          textarea {
            width: 100%;
            line-height: 170%;
            letter-spacing: -0.04em;
            font-size: 14px;
            white-space: inherit;
          }
          /* span {
            position: absolute;
            right: 0;
            bottom: 0;
          } */
        }
        .max_length {
          display: block;
          font-size: 14px;
          line-height: 170%;
          letter-spacing: -0.04em;
          color: #a2a7b1;
          text-align: right;
        }
      }
    }

    /* 뉴스레터 빌터 컴포넌트2  */
    .newsletter_special_component {
      margin-top: 90px;
      position: relative;
      > .picture_component {
        margin-bottom: 90px;
        > div {
          position: relative;
          margin-bottom: 20px;
          .special_tag {
            background: rgba(255, 80, 122, 0.2);
            border-radius: 6px;
            transform: rotate(-10deg);
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.04em;
            color: #ff507a;
            position: absolute;
            transform: rotate(-10deg);
            padding: 4px 10px;
            top: -32px;
            left: -30px;
          }
          input[type="text"] {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #374553;
            border: none;
            padding-left: 0;
            margin-bottom: 20px;
            &::placeholder {
              color: var(--grey5);
            }
          }
          h2 {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #374553;
            border: none;
            padding-left: 0;
            margin-bottom: 20px;
          }
          .text_box {
          }
        }
        .narrow_component {
          div {
            &:nth-child(1) {
              margin-right: 30px;
            }
            &.imgbox_narrow {
              width: 218.5px;
              height: 248px;
              background-color: var(--grey1);
              display: inline-block;
              cursor: pointer;
              position: relative;
              &:hover {
                transition-duration: 0.3s;
                span {
                  display: block;
                }
              }
              img {
                width: 100%;
                height: 100%;
              }
              > div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                .newsbuilder_picture_icon {
                  width: 20px !important;
                  height: 20px !important;
                  display: block;
                  margin: 0 auto;
                  margin-bottom: 6px;
                }
                p {
                  color: #646f7c;
                }
              }
              // 호버시 사진재선택
              span {
                position: absolute;
                top: 0;
                left: 0;
                width: 218.5px;
                height: 248px;
                background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.5),
                  rgba(0, 0, 0, 0.5)
                );
                display: none;
                > div {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  .newsbuilder_picture_icon {
                    width: 20px !important;
                    height: 20px !important;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 6px;
                  }
                  p {
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    letter-spacing: -0.04em;
                    color: var(--white);
                  }
                }
              }
            }
            &.textarea {
              width: 218.5px;
              height: 248px;
              display: inline-block;
              vertical-align: top;
              textarea {
                width: 218.5px;
                height: 248px;
                font-size: 14px;
                line-height: 170%;
                &::placeholder {
                  color: var(--grey7);
                  text-align: left;
                  white-space: normal;
                }
              }
            }
          }

          /* 뉴스레터 빌터 컴포넌트2 - 이미지 업로드 */
          .imgbox_upload {
            width: 218.5px;
            height: 248px;
            padding-top: 0 !important;
            cursor: pointer;
            position: relative;
            img {
              width: 218.5px;
              height: 248px;
              display: block;
              object-fit: cover;
            }
            span {
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
              display: none;
              width: 100%;
              height: 100%;
              > div {
                padding-top: 117px;
                margin-right: 0;
                img {
                  width: 20px;
                  height: 20px;
                  display: block;
                  margin: 0 auto;
                  margin-bottom: 6px;
                }
                p {
                  text-align: center;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 140%;
                  letter-spacing: -0.04em;
                  color: var(--white);
                }
              }
            }
            &:hover {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
    .imgbox_upload_done {
      cursor: auto;
      padding-top: 0 !important;
      height: 248px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text_box {
      width: 218.5px;
      height: 248px;
      display: inline-block;
      vertical-align: top;
      p {
        line-height: 170%;
      }
    }
  }

  .newsletter_builder_footer {
    width: 100%;
    height: 110px;
    background: #f2f4f6;
    z-index: 20;
    position: relative;
    margin-top: 110px;
    padding: 30px;
    > div {
      > img {
        width: 104px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-top: 10px;
      }
      ul {
        display: inline-block;
        vertical-align: middle;
        float: right;
        li {
          margin-bottom: 10px;
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
`;

// 뉴스레터 빌더 헤더
export const NewLetterHeader = styled.div`
  width: 100%;
  height: 60px;
  background: var(--main);
  border-radius: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;
  border-bottom: 1px solid #e9ebee;
  > ul {
    position: absolute;
    left: 24px;
    top: 12px;
    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #646f7c;
      display: inline-block;
      vertical-align: middle;
      padding: 8px 10px;
      border-radius: 6px;
      margin-right: 8px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.04em;
      }
    }
  }
  > div {
    position: absolute;
    right: 24px;
    top: 12px;
    ul {
      li {
        border: 1px solid var(--main);
        background-color: var(--main);
        display: inline-block;
        vertical-align: middle;
        padding: 8px 10px;
        border-radius: 6px;
        margin-right: 8px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
        }
        p {
          display: inline-block;
          vertical-align: middle;
          color: var(--white);
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          text-align: center;
          letter-spacing: -0.04em;
        }
      }
    }
  }
`;

// Lms 아무것도 없을 때
export const LmsEmpty = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  height: 265px;
  ul {
    border-radius: 6px;
    background-color: var(--grey2);
    height: 48px;
    width: 100%;
    li {
      display: inline-block;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: var(--grey6);
      padding: 14px 20px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 20%;
      }
    }
  }
  img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
    margin-top: 70px;
  }
  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center !important;
    letter-spacing: -0.04em;
    color: var(--grey5);
    width: 100% !important;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #646f7c;
    margin-top: 8px;
    text-align: center !important;
    display: block;
  }
`;

// Lms 학부모 구독관리
export const LmsSubscribe = styled.div`
  min-height: calc(100vh - 38px);
  min-width: calc(100% - 320px);
  display: inline-block;
  background-color: var(--grey2);
  vertical-align: top;
  padding: 32px 40px;
  padding-top: 82px;
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 140%;
    letter-spacing: -0.04em;
  }
  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.04em;
    margin-bottom: 20px;
    display: block;
  }
  > div {
    margin-bottom: 50px;
    ul {
      /* width: 336px; */
      width: 32.7%;
      padding: 24px;
      background: var(--white);
      border-radius: 10px;
      display: inline-block;
      margin-right: 10px;
      box-sizing: border-box;
      &:last-child {
        margin-right: 0px !important;
      }
      li {
        span {
          img {
            display: inline-block;
            width: 22px;
            height: 22px;
            vertical-align: middle;
            margin-right: 25px;
          }
          p {
            display: inline-block;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #646f7c;
            vertical-align: middle;
          }
          b {
            display: inline-block;
            float: right;
            cursor: pointer;
            color: var(--main);
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
          }
        }
        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #374553;
          margin-left: 47px;
          margin-bottom: 20px;
        }
        button {
          padding: 11px 16px;
          height: 42px;
          background: #f0ebff;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #6c38ff;
        }
      }
    }
    // 결제 내역
    table {
      width: 100%;
      border-radius: 10px;
      border-spacing: 0;
      tbody {
        width: 100%;
        border-radius: 10px;
        tr {
          width: 100%;

          padding: 24px !important;
          display: block;
          background-color: var(--white);
          border-bottom: 1px solid var(--grey3);
          td {
            width: 100%;
            display: block;
            h6 {
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.04em;
              margin-bottom: 4px;
            }
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;
              letter-spacing: -0.04em;
              color: #a2a7b1;
            }
            span {
              float: right;
            }
          }
        }
      }
    }
  }

  // 구독관리
  .subscribe_setting_all {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    z-index: 99;
    > div {
      position: relative;
      width: 100%;
      height: 100%;
      .subscribe_setting_white {
        position: absolute;
        top: 0;
        right: 0;
        width: 436px;
        height: 100%;
        background-color: var(--white);
        padding: 27px 24px;
        box-sizing: border-box;
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: var(--text);
          margin-bottom: 27.5px;
        }
        .xi-close-min {
          position: absolute;
          top: 28px;
          right: 24px;
          font-size: 24px;
          color: var(--grey4);
          cursor: pointer;
        }
        > div {
          margin-bottom: 24px;
          text-align: left;
          > div {
            h6 {
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.04em;
              color: var(--grey6);
              margin-bottom: 6px;
              text-align: left;
            }
          }
          input {
            padding: 14px 16px;
            width: 388px;
            height: 48px;
            border: 1px solid var(--grey3);
            border-radius: 8px;
            &.grey_input {
              background-color: var(--grey1);
            }
          }
        }
        > span {
          padding: 18px 14px;
          background-color: var(--grey1);
          display: block;
          box-sizing: border-box;
          text-align: left;
          font-size: 14px;
          line-height: 170%;
          color: var(--grey6);
          letter-spacing: -0.08em;
          border-radius: 6px;
          position: relative;
          strong {
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: var(--grey6);
            margin-bottom: 10px;
            display: block;
          }
          p {
            display: inline-block;
            vertical-align: middle;
            color: var(--grey6);
            line-height: 170%;
            span {
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .subscription_text {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      text-decoration-line: underline;
      color: #a2a7b1;
      margin-top: 21px;
      cursor: pointer;
    }
  }
`;
