import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { Modal, Alert } from "Components";

// api
import { mainApi } from "Utils/api/api";


const UserNewsListLogic = ({ loading, setLoading, url }) => {


    // 기본 state 관리
    const [state, setState] = useState({
        data_loading: true,
        data_news: [],
        data_comments: [],
        data_comments_total: 0,
        data_related_news: [],
        search_keyword: "",
        modal: { open: false, type: "", id: "" },
        grade_type: null, // 1학년, 2학년, 3~4학년, 5~6학년
        alert: null,
      });

      const [currentPage, setCurrentPage] = useState(1);

      useEffect(() => {
      
        getNewsList();

      }, [url, state.grade_type]);
      // 데이터 저장 state 관리
      const [inputData, setInputData] = useState({
        comment: "",
      });
      const dataList = {};


      


      ////////////////  API  ////////////////
      // 상세 조회
      const getNewsList = async () => {
        try {

            const userId = url.current.split('/')[2]; 
            console.log("userId : ", userId);

          const {
            data: { data },
          } = await mainApi.getUserNewsList({
            id: userId,
          });
          console.log(">>>");
          console.log(data);

          
          setState({
            ...state,
            data_loading: false,
            data_news: data.newsList,
            user_nick_name: data.newsList[0].users.nick_name,
            user_profile_image_url: data.newsList[0].users.profile_image_url,
            intro: data.newsList[0].users.intro,


            // data_comments_total: data.comments.total,
            // data_related_news: data.related_news,
            // grade_type: data.news.contents ? data.news.contents[0].grade : "1",
          });
        } catch (error) {
          setStateValue("alert", {
            title: error.response.data.message,
            sub: null,
            isOk: () => setStateValue("alert", null),
            isCancel: null,
          });
          console.log(error);
          // alert(res.data.message);
        } finally { 
          setLoading(false);
        }
      };



        // 좋아요
  const likedNews = async (id,e) => {

    e.stopPropagation();

    if (localStorage.getItem("user_token")) {

      try {

        const { data } = await mainApi.setLikeNews({
          id: id,
        });        
        getNewsList();

      } catch (error) {
        console.log(error);
        // alert(res.data.message);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else {

      setStateValue("alert", {
        title: "좋아요는 회원만 가능 합니다.",
        sub: "로그인 하시러 가시겠습니까?",
        isOk: () => (window.location.href = "/login/student"),
        isCancel: () => setStateValue("alert", null),
      });

    }
  };




      const setStateValue = (key, value) => {
        setState({ ...state, [key]: value });
      };


      return {
        state,
        data: { dataList, currentPage },
        func: {
            setStateValue,
            likedNews
        },

      };


};




export default UserNewsListLogic;